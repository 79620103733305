"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var NumberNumericalExpressionAnalyzer_1, _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.NumberNumericalExpressionAnalyzer = void 0;
const inversify_1 = require("inversify");
const IRandomGenerator_1 = require("../../interfaces/utils/IRandomGenerator");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const NumberUtils_1 = require("../../utils/NumberUtils");
let NumberNumericalExpressionAnalyzer = NumberNumericalExpressionAnalyzer_1 = class NumberNumericalExpressionAnalyzer {
    constructor(randomGenerator) {
        this.numberFactorsMap = new Map();
        this.randomGenerator = randomGenerator;
    }
    analyze(number, additionalPartsCount) {
        if (isNaN(number)) {
            throw new Error('Given value is NaN');
        }
        if (NumberUtils_1.NumberUtils.isUnsafeNumber(number)) {
            return [number];
        }
        const additionParts = this.generateAdditionParts(number, additionalPartsCount);
        return additionParts.map((addition) => this.mixWithMultiplyParts(addition));
    }
    generateAdditionParts(number, additionalPartsCount) {
        const additionParts = [];
        const upperNumberLimit = Math.min(Math.abs(number * 2), Number.MAX_SAFE_INTEGER);
        const from = Math.min(-NumberNumericalExpressionAnalyzer_1.delta, -upperNumberLimit);
        const to = Math.max(NumberNumericalExpressionAnalyzer_1.delta, upperNumberLimit);
        let temporarySum = 0;
        for (let i = 0; i < additionalPartsCount; i++) {
            if (i < additionalPartsCount - 1) {
                let addition = this.randomGenerator.getRandomInteger(from, to);
                const isUnsafeCombination = NumberUtils_1.NumberUtils.isUnsafeNumber(temporarySum + addition);
                if (isUnsafeCombination) {
                    addition = -addition;
                }
                additionParts.push(addition);
                temporarySum += addition;
            }
            else {
                const combination = number - temporarySum;
                const isUnsafeCombination = NumberUtils_1.NumberUtils.isUnsafeNumber(combination);
                if (isUnsafeCombination) {
                    additionParts.push(0 - temporarySum);
                    additionParts.push(number);
                }
                else {
                    additionParts.push(combination);
                }
            }
        }
        return additionParts;
    }
    mixWithMultiplyParts(number) {
        var _a;
        const shouldMixWithMultiplyParts = this.randomGenerator.getMathRandom() > 0.5;
        if (!shouldMixWithMultiplyParts || number === 0) {
            return number;
        }
        let factors = (_a = this.numberFactorsMap.get(number)) !== null && _a !== void 0 ? _a : null;
        if (!factors) {
            factors = NumberUtils_1.NumberUtils.getFactors(number);
            this.numberFactorsMap.set(number, factors);
        }
        if (!factors.length) {
            return number;
        }
        const factor = factors[this.randomGenerator.getRandomInteger(0, factors.length - 1)];
        return [factor, number / factor];
    }
};
NumberNumericalExpressionAnalyzer.defaultAdditionalPartsCount = 3;
NumberNumericalExpressionAnalyzer.delta = 10000;
NumberNumericalExpressionAnalyzer = NumberNumericalExpressionAnalyzer_1 = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __metadata("design:paramtypes", [typeof (_a = typeof IRandomGenerator_1.IRandomGenerator !== "undefined" && IRandomGenerator_1.IRandomGenerator) === "function" ? _a : Object])
], NumberNumericalExpressionAnalyzer);
exports.NumberNumericalExpressionAnalyzer = NumberNumericalExpressionAnalyzer;
