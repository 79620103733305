"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var ObjectExpressionCalleeDataExtractor_1;
Object.defineProperty(exports, "__esModule", { value: true });
exports.ObjectExpressionCalleeDataExtractor = void 0;
const inversify_1 = require("inversify");
const estraverse = __importStar(require("@javascript-obfuscator/estraverse"));
const AbstractCalleeDataExtractor_1 = require("./AbstractCalleeDataExtractor");
const NodeGuards_1 = require("../../../node/NodeGuards");
const NodeStatementUtils_1 = require("../../../node/NodeStatementUtils");
let ObjectExpressionCalleeDataExtractor = ObjectExpressionCalleeDataExtractor_1 = class ObjectExpressionCalleeDataExtractor extends AbstractCalleeDataExtractor_1.AbstractCalleeDataExtractor {
    static isValidTargetPropertyNode(propertyNode, nextItemInCallsChain) {
        if (!propertyNode.key) {
            return false;
        }
        const isTargetPropertyNodeWithIdentifierKey = NodeGuards_1.NodeGuards.isIdentifierNode(propertyNode.key) && propertyNode.key.name === nextItemInCallsChain;
        const isTargetPropertyNodeWithLiteralKey = NodeGuards_1.NodeGuards.isLiteralNode(propertyNode.key) &&
            Boolean(propertyNode.key.value) &&
            propertyNode.key.value === nextItemInCallsChain;
        return isTargetPropertyNodeWithIdentifierKey || isTargetPropertyNodeWithLiteralKey;
    }
    extract(blockScopeBody, callee) {
        if (!NodeGuards_1.NodeGuards.isMemberExpressionNode(callee)) {
            return null;
        }
        const objectMembersCallsChain = this.createObjectMembersCallsChain([], callee);
        if (!objectMembersCallsChain.length) {
            return null;
        }
        const functionExpressionName = objectMembersCallsChain[objectMembersCallsChain.length - 1];
        const calleeBlockStatement = this.getCalleeBlockStatement(NodeStatementUtils_1.NodeStatementUtils.getParentNodeWithStatements(blockScopeBody[0]), objectMembersCallsChain);
        if (!calleeBlockStatement) {
            return null;
        }
        return {
            callee: calleeBlockStatement,
            name: functionExpressionName
        };
    }
    createObjectMembersCallsChain(currentChain, memberExpression) {
        if (NodeGuards_1.NodeGuards.isIdentifierNode(memberExpression.property) && !memberExpression.computed) {
            currentChain.unshift(memberExpression.property.name);
        }
        else if (NodeGuards_1.NodeGuards.isLiteralNode(memberExpression.property) &&
            (typeof memberExpression.property.value === 'string' ||
                typeof memberExpression.property.value === 'number')) {
            currentChain.unshift(memberExpression.property.value);
        }
        else {
            return currentChain;
        }
        if (NodeGuards_1.NodeGuards.isMemberExpressionNode(memberExpression.object)) {
            return this.createObjectMembersCallsChain(currentChain, memberExpression.object);
        }
        else if (NodeGuards_1.NodeGuards.isIdentifierNode(memberExpression.object)) {
            currentChain.unshift(memberExpression.object.name);
        }
        return currentChain;
    }
    getCalleeBlockStatement(targetNode, objectMembersCallsChain) {
        const objectName = objectMembersCallsChain.shift();
        if (!objectName) {
            return null;
        }
        let calleeBlockStatement = null;
        estraverse.traverse(targetNode, {
            enter: (node) => {
                if (NodeGuards_1.NodeGuards.isVariableDeclaratorNode(node) &&
                    NodeGuards_1.NodeGuards.isIdentifierNode(node.id) &&
                    node.init &&
                    NodeGuards_1.NodeGuards.isObjectExpressionNode(node.init) &&
                    node.id.name === objectName) {
                    calleeBlockStatement = this.findCalleeBlockStatement(node.init.properties, objectMembersCallsChain);
                    return estraverse.VisitorOption.Break;
                }
            }
        });
        return calleeBlockStatement;
    }
    findCalleeBlockStatement(objectExpressionProperties, objectMembersCallsChain) {
        const nextItemInCallsChain = objectMembersCallsChain.shift();
        if (!nextItemInCallsChain) {
            return null;
        }
        for (const propertyNode of objectExpressionProperties) {
            if (!NodeGuards_1.NodeGuards.isPropertyNode(propertyNode)) {
                continue;
            }
            if (!ObjectExpressionCalleeDataExtractor_1.isValidTargetPropertyNode(propertyNode, nextItemInCallsChain)) {
                continue;
            }
            if (NodeGuards_1.NodeGuards.isObjectExpressionNode(propertyNode.value)) {
                return this.findCalleeBlockStatement(propertyNode.value.properties, objectMembersCallsChain);
            }
            if (NodeGuards_1.NodeGuards.isFunctionExpressionNode(propertyNode.value)) {
                return propertyNode.value.body;
            }
        }
        return null;
    }
};
ObjectExpressionCalleeDataExtractor = ObjectExpressionCalleeDataExtractor_1 = __decorate([
    (0, inversify_1.injectable)()
], ObjectExpressionCalleeDataExtractor);
exports.ObjectExpressionCalleeDataExtractor = ObjectExpressionCalleeDataExtractor;
