"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ServiceIdentifiers = void 0;
var ServiceIdentifiers;
(function (ServiceIdentifiers) {
    ServiceIdentifiers["Factory__ICalleeDataExtractor"] = "Factory<ICalleeDataExtractor>";
    ServiceIdentifiers["Factory__ICodeTransformer"] = "Factory<ICodeTransformer[]>";
    ServiceIdentifiers["Factory__IControlFlowCustomNode"] = "Factory<IControlFlowCustomNode>";
    ServiceIdentifiers["Factory__IControlFlowReplacer"] = "Factory<IControlFlowReplacer>";
    ServiceIdentifiers["Factory__ICustomCodeHelper"] = "Factory<ICustomCodeHelper>";
    ServiceIdentifiers["Factory__ICustomCodeHelperGroup"] = "Factory<ICustomCodeHelperGroup>";
    ServiceIdentifiers["Factory__IDeadCodeInjectionCustomNode"] = "Factory<IDeadCodeInjectionCustomNode>";
    ServiceIdentifiers["Factory__IIdentifierNamesGenerator"] = "Factory<IIdentifierNamesGenerator>";
    ServiceIdentifiers["Factory__INodeGuard"] = "Factory<INodeGuard>";
    ServiceIdentifiers["Factory__INodeTransformer"] = "Factory<INodeTransformer[]>";
    ServiceIdentifiers["Factory__IObfuscationResult"] = "Factory<IObfuscationResult>";
    ServiceIdentifiers["Factory__IObjectExpressionKeysTransformerCustomNode"] = "Factory<IObjectExpressionKeysTransformerCustomNode>";
    ServiceIdentifiers["Factory__IObjectExpressionExtractor"] = "Factory<IObjectExpressionExtractor>";
    ServiceIdentifiers["Factory__IStringArrayCustomNode"] = "Factory<IStringArrayCustomNode>";
    ServiceIdentifiers["Factory__IStringArrayIndexNode"] = "Factory<IStringArrayIndexNode>";
    ServiceIdentifiers["Factory__TControlFlowStorage"] = "Factory<TControlFlowStorage>";
    ServiceIdentifiers["IArrayUtils"] = "IArrayUtils";
    ServiceIdentifiers["ICalleeDataExtractor"] = "ICalleeDataExtractor";
    ServiceIdentifiers["ICallsGraphAnalyzer"] = "ICallsGraphAnalyzer";
    ServiceIdentifiers["ICodeTransformer"] = "ICodeTransformer";
    ServiceIdentifiers["ICodeTransformerNamesGroupsBuilder"] = "ICodeTransformerNamesGroupsBuilder";
    ServiceIdentifiers["ICodeTransformersRunner"] = "ICodeTransformersRunner";
    ServiceIdentifiers["ICryptUtils"] = "ICryptUtils";
    ServiceIdentifiers["ICryptUtilsStringArray"] = "ICryptUtilsStringArray";
    ServiceIdentifiers["ICustomCodeHelper"] = "ICustomCodeHelper";
    ServiceIdentifiers["ICustomCodeHelperGroup"] = "ICustomCodeHelperGroup";
    ServiceIdentifiers["IControlFlowReplacer"] = "IControlFlowReplacer";
    ServiceIdentifiers["ICustomCodeHelperFormatter"] = "ICustomCodeHelperFormatter";
    ServiceIdentifiers["ICustomCodeHelperObfuscator"] = "ICustomCodeHelperObfuscator";
    ServiceIdentifiers["IEscapeSequenceEncoder"] = "IEscapeSequenceEncoder";
    ServiceIdentifiers["IGlobalIdentifierNamesCacheStorage"] = "IGlobalIdentifierNamesCacheStorage";
    ServiceIdentifiers["IIdentifierNamesGenerator"] = "IIdentifierNamesGenerator";
    ServiceIdentifiers["IIdentifierReplacer"] = "IIdentifierReplacer";
    ServiceIdentifiers["IJavaScriptObfuscator"] = "IJavaScriptObfuscator";
    ServiceIdentifiers["ILevelledTopologicalSorter"] = "ILevelledTopologicalSorter";
    ServiceIdentifiers["ILiteralNodesCacheStorage"] = "ILiteralNodesCacheStorage";
    ServiceIdentifiers["ILogger"] = "ILogger";
    ServiceIdentifiers["INodeGuard"] = "INodeGuard";
    ServiceIdentifiers["INodeTransformer"] = "INodeTransformer";
    ServiceIdentifiers["INodeTransformerNamesGroupsBuilder"] = "INodeTransformerNamesGroupsBuilder";
    ServiceIdentifiers["INodeTransformersRunner"] = "INodeTransformersRunner";
    ServiceIdentifiers["INumberNumericalExpressionAnalyzer"] = "INumberNumericalExpressionAnalyzer";
    ServiceIdentifiers["IObfuscationResult"] = "IObfuscationResult";
    ServiceIdentifiers["IOptions"] = "IOptions";
    ServiceIdentifiers["IOptionsNormalizer"] = "IOptionsNormalizer";
    ServiceIdentifiers["IPrevailingKindOfVariablesAnalyzer"] = "IPrevailingKindOfVariablesAnalyzer";
    ServiceIdentifiers["IPropertyIdentifierNamesCacheStorage"] = "IPropertyIdentifierNamesCacheStorage";
    ServiceIdentifiers["IObjectExpressionExtractor"] = "IObjectExpressionExtractor";
    ServiceIdentifiers["IRandomGenerator"] = "IRandomGenerator";
    ServiceIdentifiers["IRenamePropertiesReplacer"] = "IRenamePropertiesReplacer";
    ServiceIdentifiers["IScopeIdentifiersTraverser"] = "IScopeIdentifiersTraverser";
    ServiceIdentifiers["ISourceCode"] = "ISourceCode";
    ServiceIdentifiers["IScopeAnalyzer"] = "IScopeAnalyzer";
    ServiceIdentifiers["IStringArrayIndexNode"] = "IStringArrayIndexNode";
    ServiceIdentifiers["IStringArrayScopeCallsWrappersDataStorage"] = "IStringArrayScopeCallsWrappersDataStorage";
    ServiceIdentifiers["IStringArrayStorage"] = "IStringArrayStorage";
    ServiceIdentifiers["IStringArrayStorageAnalyzer"] = "IStringArrayStorageAnalyzer";
    ServiceIdentifiers["IThroughIdentifierReplacer"] = "IThroughIdentifierReplacer";
    ServiceIdentifiers["IVisitedLexicalScopeNodesStackStorage"] = "IVisitedLexicalScopeNodesStackStorage";
    ServiceIdentifiers["Newable__ICustomNode"] = "Newable<ICustomNode>";
    ServiceIdentifiers["Newable__TControlFlowStorage"] = "Newable<TControlFlowStorage>";
    ServiceIdentifiers["TCustomNodeGroupStorage"] = "TCustomNodeGroupStorage";
    ServiceIdentifiers["TInputOptions"] = "TInputOptions";
})(ServiceIdentifiers = exports.ServiceIdentifiers || (exports.ServiceIdentifiers = {}));
