"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var PrevailingKindOfVariablesAnalyzer_1, _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrevailingKindOfVariablesAnalyzer = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const estraverse = __importStar(require("@javascript-obfuscator/estraverse"));
const IArrayUtils_1 = require("../../interfaces/utils/IArrayUtils");
const NodeGuards_1 = require("../../node/NodeGuards");
let PrevailingKindOfVariablesAnalyzer = PrevailingKindOfVariablesAnalyzer_1 = class PrevailingKindOfVariablesAnalyzer {
    constructor(arrayUtils) {
        this.prevailingKindOfVariables = PrevailingKindOfVariablesAnalyzer_1.defaultKindOfVariables;
        this.arrayUtils = arrayUtils;
    }
    analyze(astTree) {
        var _a;
        const variableKinds = [];
        estraverse.traverse(astTree, {
            enter: (node) => {
                if (!NodeGuards_1.NodeGuards.isVariableDeclarationNode(node)) {
                    return;
                }
                variableKinds.push(node.kind);
            }
        });
        this.prevailingKindOfVariables = (_a = this.arrayUtils.findMostOccurringElement(variableKinds)) !== null && _a !== void 0 ? _a : PrevailingKindOfVariablesAnalyzer_1.defaultKindOfVariables;
    }
    getPrevailingKind() {
        return this.prevailingKindOfVariables;
    }
};
PrevailingKindOfVariablesAnalyzer.defaultKindOfVariables = 'var';
PrevailingKindOfVariablesAnalyzer = PrevailingKindOfVariablesAnalyzer_1 = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IArrayUtils)),
    __metadata("design:paramtypes", [typeof (_a = typeof IArrayUtils_1.IArrayUtils !== "undefined" && IArrayUtils_1.IArrayUtils) === "function" ? _a : Object])
], PrevailingKindOfVariablesAnalyzer);
exports.PrevailingKindOfVariablesAnalyzer = PrevailingKindOfVariablesAnalyzer;
