"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c, _d, _e;
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomCodeHelpersTransformer = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const TCustomCodeHelperGroupStorage_1 = require("../../types/storages/TCustomCodeHelperGroupStorage");
const IOptions_1 = require("../../interfaces/options/IOptions");
const IRandomGenerator_1 = require("../../interfaces/utils/IRandomGenerator");
const ICallsGraphAnalyzer_1 = require("../../interfaces/analyzers/calls-graph-analyzer/ICallsGraphAnalyzer");
const IPrevailingKindOfVariablesAnalyzer_1 = require("../../interfaces/analyzers/calls-graph-analyzer/IPrevailingKindOfVariablesAnalyzer");
const NodeTransformer_1 = require("../../enums/node-transformers/NodeTransformer");
const NodeTransformationStage_1 = require("../../enums/node-transformers/NodeTransformationStage");
const AbstractNodeTransformer_1 = require("../AbstractNodeTransformer");
const NodeGuards_1 = require("../../node/NodeGuards");
let CustomCodeHelpersTransformer = class CustomCodeHelpersTransformer extends AbstractNodeTransformer_1.AbstractNodeTransformer {
    constructor(callsGraphAnalyzer, prevailingKindOfVariablesAnalyzer, customCodeHelperGroupStorage, randomGenerator, options) {
        super(randomGenerator, options);
        this.runAfter = [
            NodeTransformer_1.NodeTransformer.ParentificationTransformer,
            NodeTransformer_1.NodeTransformer.VariablePreserveTransformer
        ];
        this.callsGraphData = [];
        this.callsGraphAnalyzer = callsGraphAnalyzer;
        this.prevailingKindOfVariablesAnalyzer = prevailingKindOfVariablesAnalyzer;
        this.customCodeHelperGroupStorage = customCodeHelperGroupStorage;
    }
    getVisitor(nodeTransformationStage) {
        switch (nodeTransformationStage) {
            case NodeTransformationStage_1.NodeTransformationStage.Preparing:
                return {
                    leave: (node, parentNode) => {
                        if (NodeGuards_1.NodeGuards.isProgramNode(node)) {
                            this.prepareNode(node, parentNode);
                            this.appendCustomNodesForPreparingStage(node, parentNode);
                            return this.transformNode(node, parentNode);
                        }
                    }
                };
            default:
                return {
                    leave: (node, parentNode) => {
                        if (NodeGuards_1.NodeGuards.isProgramNode(node)) {
                            this.appendCustomNodesForStage(nodeTransformationStage, node, parentNode);
                        }
                        return node;
                    }
                };
        }
    }
    prepareNode(node, parentNode) {
        this.callsGraphData = this.callsGraphAnalyzer.analyze(node);
        this.prevailingKindOfVariablesAnalyzer.analyze(node);
    }
    transformNode(node, parentNode) {
        return node;
    }
    appendCustomNodesForPreparingStage(node, parentNode) {
        this.customCodeHelperGroupStorage
            .getStorage()
            .forEach((customCodeHelperGroup) => {
            var _a;
            customCodeHelperGroup.initialize();
            (_a = customCodeHelperGroup.appendOnPreparingStage) === null || _a === void 0 ? void 0 : _a.call(customCodeHelperGroup, node, this.callsGraphData);
        });
    }
    appendCustomNodesForStage(nodeTransformationStage, node, parentNode) {
        this.customCodeHelperGroupStorage
            .getStorage()
            .forEach((customCodeHelperGroup) => {
            var _a;
            const methodName = `appendOn${nodeTransformationStage}Stage`;
            (_a = customCodeHelperGroup[methodName]) === null || _a === void 0 ? void 0 : _a.call(customCodeHelperGroup, node, this.callsGraphData);
        });
    }
};
CustomCodeHelpersTransformer = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.ICallsGraphAnalyzer)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IPrevailingKindOfVariablesAnalyzer)),
    __param(2, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.TCustomNodeGroupStorage)),
    __param(3, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(4, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [typeof (_a = typeof ICallsGraphAnalyzer_1.ICallsGraphAnalyzer !== "undefined" && ICallsGraphAnalyzer_1.ICallsGraphAnalyzer) === "function" ? _a : Object, typeof (_b = typeof IPrevailingKindOfVariablesAnalyzer_1.IPrevailingKindOfVariablesAnalyzer !== "undefined" && IPrevailingKindOfVariablesAnalyzer_1.IPrevailingKindOfVariablesAnalyzer) === "function" ? _b : Object, typeof (_c = typeof TCustomCodeHelperGroupStorage_1.TCustomCodeHelperGroupStorage !== "undefined" && TCustomCodeHelperGroupStorage_1.TCustomCodeHelperGroupStorage) === "function" ? _c : Object, typeof (_d = typeof IRandomGenerator_1.IRandomGenerator !== "undefined" && IRandomGenerator_1.IRandomGenerator) === "function" ? _d : Object, typeof (_e = typeof IOptions_1.IOptions !== "undefined" && IOptions_1.IOptions) === "function" ? _e : Object])
], CustomCodeHelpersTransformer);
exports.CustomCodeHelpersTransformer = CustomCodeHelpersTransformer;
