"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c, _d, _e, _f;
Object.defineProperty(exports, "__esModule", { value: true });
exports.StringArrayCodeHelper = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const TIdentifierNamesGeneratorFactory_1 = require("../../types/container/generators/TIdentifierNamesGeneratorFactory");
const ICustomCodeHelperFormatter_1 = require("../../interfaces/custom-code-helpers/ICustomCodeHelperFormatter");
const ICustomCodeHelperObfuscator_1 = require("../../interfaces/custom-code-helpers/ICustomCodeHelperObfuscator");
const IOptions_1 = require("../../interfaces/options/IOptions");
const IRandomGenerator_1 = require("../../interfaces/utils/IRandomGenerator");
const IStringArrayStorage_1 = require("../../interfaces/storages/string-array-transformers/IStringArrayStorage");
const Initializable_1 = require("../../decorators/Initializable");
const StringArrayTemplate_1 = require("./templates/string-array/StringArrayTemplate");
const AbstractCustomCodeHelper_1 = require("../AbstractCustomCodeHelper");
const NodeUtils_1 = require("../../node/NodeUtils");
const StringUtils_1 = require("../../utils/StringUtils");
let StringArrayCodeHelper = class StringArrayCodeHelper extends AbstractCustomCodeHelper_1.AbstractCustomCodeHelper {
    constructor(identifierNamesGeneratorFactory, customCodeHelperFormatter, customCodeHelperObfuscator, randomGenerator, options) {
        super(identifierNamesGeneratorFactory, customCodeHelperFormatter, customCodeHelperObfuscator, randomGenerator, options);
    }
    initialize(stringArrayStorage, stringArrayFunctionName) {
        this.stringArrayStorage = stringArrayStorage;
        this.stringArrayFunctionName = stringArrayFunctionName;
    }
    getNodeStructure(codeHelperTemplate) {
        return NodeUtils_1.NodeUtils.convertCodeToStructure(codeHelperTemplate);
    }
    getCodeHelperTemplate() {
        const stringArrayName = this.identifierNamesGenerator.generateNext();
        return this.customCodeHelperFormatter.formatTemplate((0, StringArrayTemplate_1.StringArrayTemplate)(), {
            stringArrayFunctionName: this.stringArrayFunctionName,
            stringArrayName: stringArrayName,
            stringArrayStorageItems: this.getEncodedStringArrayStorageItems()
        });
    }
    getEncodedStringArrayStorageItems() {
        return Array
            .from(this.stringArrayStorage.getStorage().values())
            .map((stringArrayStorageItemData) => {
            const escapedEncodedValue = StringUtils_1.StringUtils.escapeJsString(stringArrayStorageItemData.encodedValue);
            return `'${escapedEncodedValue}'`;
        })
            .toString();
    }
};
__decorate([
    (0, Initializable_1.initializable)(),
    __metadata("design:type", typeof (_a = typeof IStringArrayStorage_1.IStringArrayStorage !== "undefined" && IStringArrayStorage_1.IStringArrayStorage) === "function" ? _a : Object)
], StringArrayCodeHelper.prototype, "stringArrayStorage", void 0);
__decorate([
    (0, Initializable_1.initializable)(),
    __metadata("design:type", String)
], StringArrayCodeHelper.prototype, "stringArrayFunctionName", void 0);
StringArrayCodeHelper = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IIdentifierNamesGenerator)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.ICustomCodeHelperFormatter)),
    __param(2, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.ICustomCodeHelperObfuscator)),
    __param(3, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(4, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [typeof (_b = typeof TIdentifierNamesGeneratorFactory_1.TIdentifierNamesGeneratorFactory !== "undefined" && TIdentifierNamesGeneratorFactory_1.TIdentifierNamesGeneratorFactory) === "function" ? _b : Object, typeof (_c = typeof ICustomCodeHelperFormatter_1.ICustomCodeHelperFormatter !== "undefined" && ICustomCodeHelperFormatter_1.ICustomCodeHelperFormatter) === "function" ? _c : Object, typeof (_d = typeof ICustomCodeHelperObfuscator_1.ICustomCodeHelperObfuscator !== "undefined" && ICustomCodeHelperObfuscator_1.ICustomCodeHelperObfuscator) === "function" ? _d : Object, typeof (_e = typeof IRandomGenerator_1.IRandomGenerator !== "undefined" && IRandomGenerator_1.IRandomGenerator) === "function" ? _e : Object, typeof (_f = typeof IOptions_1.IOptions !== "undefined" && IOptions_1.IOptions) === "function" ? _f : Object])
], StringArrayCodeHelper);
exports.StringArrayCodeHelper = StringArrayCodeHelper;
