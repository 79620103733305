"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.CryptUtilsStringArray = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../container/ServiceIdentifiers");
const IRandomGenerator_1 = require("../interfaces/utils/IRandomGenerator");
const Base64AlphabetSwapped_1 = require("../constants/Base64AlphabetSwapped");
const CryptUtils_1 = require("./CryptUtils");
let CryptUtilsStringArray = class CryptUtilsStringArray extends CryptUtils_1.CryptUtils {
    constructor(randomGenerator) {
        super(randomGenerator);
        this.base64Alphabet = Base64AlphabetSwapped_1.base64alphabetSwapped;
    }
    btoa(string) {
        const output = super.btoa(string);
        return output.replace(/=+$/, '');
    }
};
CryptUtilsStringArray = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __metadata("design:paramtypes", [typeof (_a = typeof IRandomGenerator_1.IRandomGenerator !== "undefined" && IRandomGenerator_1.IRandomGenerator) === "function" ? _a : Object])
], CryptUtilsStringArray);
exports.CryptUtilsStringArray = CryptUtilsStringArray;
