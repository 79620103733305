"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var AbstractStringArrayCallNode_1, _a, _b, _c, _d, _e, _f, _g;
Object.defineProperty(exports, "__esModule", { value: true });
exports.AbstractStringArrayCallNode = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const TIdentifierNamesGeneratorFactory_1 = require("../../types/container/generators/TIdentifierNamesGeneratorFactory");
const TStringArrayIndexNodeFactory_1 = require("../../types/container/custom-nodes/string-array-index-nodes/TStringArrayIndexNodeFactory");
const ICustomCodeHelperFormatter_1 = require("../../interfaces/custom-code-helpers/ICustomCodeHelperFormatter");
const IOptions_1 = require("../../interfaces/options/IOptions");
const IRandomGenerator_1 = require("../../interfaces/utils/IRandomGenerator");
const IStringArrayStorage_1 = require("../../interfaces/storages/string-array-transformers/IStringArrayStorage");
const StringArrayIndexesType_1 = require("../../enums/node-transformers/string-array-transformers/StringArrayIndexesType");
const StringArrayIndexNode_1 = require("../../enums/custom-nodes/string-array-index-nodes/StringArrayIndexNode");
const AbstractCustomNode_1 = require("../AbstractCustomNode");
const NodeFactory_1 = require("../../node/NodeFactory");
const NodeMetadata_1 = require("../../node/NodeMetadata");
const NodeUtils_1 = require("../../node/NodeUtils");
const IArrayUtils_1 = require("../../interfaces/utils/IArrayUtils");
let AbstractStringArrayCallNode = AbstractStringArrayCallNode_1 = class AbstractStringArrayCallNode extends AbstractCustomNode_1.AbstractCustomNode {
    constructor(identifierNamesGeneratorFactory, stringArrayIndexNodeFactory, customCodeHelperFormatter, stringArrayStorage, arrayUtils, randomGenerator, options) {
        super(identifierNamesGeneratorFactory, customCodeHelperFormatter, randomGenerator, options);
        this.stringArrayIndexNodeFactory = stringArrayIndexNodeFactory;
        this.stringArrayStorage = stringArrayStorage;
        this.arrayUtils = arrayUtils;
    }
    getStringArrayIndexNode(index) {
        var _a;
        const isPositive = index >= 0;
        const normalizedIndex = Math.abs(index);
        const stringArrayCallsIndexType = this.randomGenerator
            .getRandomGenerator()
            .pickone(this.options.stringArrayIndexesType);
        const stringArrayIndexNodeName = (_a = AbstractStringArrayCallNode_1.stringArrayIndexNodesMap.get(stringArrayCallsIndexType)) !== null && _a !== void 0 ? _a : null;
        if (!stringArrayIndexNodeName) {
            throw new Error('Invalid string array index node name');
        }
        const stringArrayCallIndexNode = this.stringArrayIndexNodeFactory(stringArrayIndexNodeName)
            .getNode(normalizedIndex);
        NodeMetadata_1.NodeMetadata.set(stringArrayCallIndexNode, { stringArrayCallLiteralNode: true });
        const hexadecimalNode = isPositive
            ? stringArrayCallIndexNode
            : NodeFactory_1.NodeFactory.unaryExpressionNode('-', stringArrayCallIndexNode);
        NodeUtils_1.NodeUtils.parentizeAst(hexadecimalNode);
        return hexadecimalNode;
    }
    getRc4KeyLiteralNode(decodeKey) {
        const rc4KeyLiteralNode = NodeFactory_1.NodeFactory.literalNode(decodeKey);
        NodeMetadata_1.NodeMetadata.set(rc4KeyLiteralNode, { stringArrayCallLiteralNode: true });
        return rc4KeyLiteralNode;
    }
};
AbstractStringArrayCallNode.stringArrayRootCallsWrapperParametersCount = 2;
AbstractStringArrayCallNode.stringArrayIndexNodesMap = new Map([
    [StringArrayIndexesType_1.StringArrayIndexesType.HexadecimalNumber, StringArrayIndexNode_1.StringArrayIndexNode.StringArrayHexadecimalNumberIndexNode],
    [StringArrayIndexesType_1.StringArrayIndexesType.HexadecimalNumericString, StringArrayIndexNode_1.StringArrayIndexNode.StringArrayHexadecimalNumericStringIndexNode]
]);
AbstractStringArrayCallNode = AbstractStringArrayCallNode_1 = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IIdentifierNamesGenerator)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IStringArrayIndexNode)),
    __param(2, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.ICustomCodeHelperFormatter)),
    __param(3, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IStringArrayStorage)),
    __param(4, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IArrayUtils)),
    __param(5, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(6, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [typeof (_a = typeof TIdentifierNamesGeneratorFactory_1.TIdentifierNamesGeneratorFactory !== "undefined" && TIdentifierNamesGeneratorFactory_1.TIdentifierNamesGeneratorFactory) === "function" ? _a : Object, typeof (_b = typeof TStringArrayIndexNodeFactory_1.TStringArrayIndexNodeFactory !== "undefined" && TStringArrayIndexNodeFactory_1.TStringArrayIndexNodeFactory) === "function" ? _b : Object, typeof (_c = typeof ICustomCodeHelperFormatter_1.ICustomCodeHelperFormatter !== "undefined" && ICustomCodeHelperFormatter_1.ICustomCodeHelperFormatter) === "function" ? _c : Object, typeof (_d = typeof IStringArrayStorage_1.IStringArrayStorage !== "undefined" && IStringArrayStorage_1.IStringArrayStorage) === "function" ? _d : Object, typeof (_e = typeof IArrayUtils_1.IArrayUtils !== "undefined" && IArrayUtils_1.IArrayUtils) === "function" ? _e : Object, typeof (_f = typeof IRandomGenerator_1.IRandomGenerator !== "undefined" && IRandomGenerator_1.IRandomGenerator) === "function" ? _f : Object, typeof (_g = typeof IOptions_1.IOptions !== "undefined" && IOptions_1.IOptions) === "function" ? _g : Object])
], AbstractStringArrayCallNode);
exports.AbstractStringArrayCallNode = AbstractStringArrayCallNode;
