"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var RenamePropertiesTransformer_1, _a, _b, _c;
Object.defineProperty(exports, "__esModule", { value: true });
exports.RenamePropertiesTransformer = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const IRenamePropertiesReplacer_1 = require("../../interfaces/node-transformers/rename-properties-transformers/replacer/IRenamePropertiesReplacer");
const IOptions_1 = require("../../interfaces/options/IOptions");
const IRandomGenerator_1 = require("../../interfaces/utils/IRandomGenerator");
const NodeTransformationStage_1 = require("../../enums/node-transformers/NodeTransformationStage");
const RenamePropertiesMode_1 = require("../../enums/node-transformers/rename-properties-transformers/RenamePropertiesMode");
const AbstractNodeTransformer_1 = require("../AbstractNodeTransformer");
const NodeGuards_1 = require("../../node/NodeGuards");
const NodeLiteralUtils_1 = require("../../node/NodeLiteralUtils");
const NodeUtils_1 = require("../../node/NodeUtils");
let RenamePropertiesTransformer = RenamePropertiesTransformer_1 = class RenamePropertiesTransformer extends AbstractNodeTransformer_1.AbstractNodeTransformer {
    constructor(renamePropertiesReplacer, randomGenerator, options) {
        super(randomGenerator, options);
        this.renamePropertiesReplacer = renamePropertiesReplacer;
    }
    static isValidPropertyNode(propertyNode, propertyKeyNode) {
        if (NodeGuards_1.NodeGuards.isIdentifierNode(propertyKeyNode) && propertyNode.computed) {
            return false;
        }
        return NodeGuards_1.NodeGuards.isIdentifierNode(propertyKeyNode) || NodeGuards_1.NodeGuards.isLiteralNode(propertyKeyNode);
    }
    getVisitor(nodeTransformationStage) {
        switch (nodeTransformationStage) {
            case NodeTransformationStage_1.NodeTransformationStage.Preparing:
                return {
                    enter: (node, parentNode) => {
                        if (parentNode) {
                            this.prepareNode(node, parentNode);
                        }
                    }
                };
            case NodeTransformationStage_1.NodeTransformationStage.RenameProperties:
                return {
                    enter: (node, parentNode) => {
                        if (parentNode) {
                            return this.transformNode(node, parentNode);
                        }
                    }
                };
            default:
                return null;
        }
    }
    prepareNode(node, parentNode) {
        if (this.options.renamePropertiesMode === RenamePropertiesMode_1.RenamePropertiesMode.Safe) {
            this.analyzeAutoExcludedPropertyNames(node, parentNode);
        }
    }
    transformNode(node, parentNode) {
        let propertyNode = null;
        if (NodeGuards_1.NodeGuards.isPropertyNode(node)) {
            propertyNode = this.transformPropertyNode(node);
        }
        else if (NodeGuards_1.NodeGuards.isPropertyDefinitionNode(node)) {
            propertyNode = this.transformPropertyDefinitionNode(node);
        }
        else if (NodeGuards_1.NodeGuards.isMemberExpressionNode(node)) {
            propertyNode = this.transformMemberExpressionNode(node);
        }
        else if (NodeGuards_1.NodeGuards.isMethodDefinitionNode(node)) {
            propertyNode = this.transformMethodDefinitionNode(node);
        }
        if (propertyNode) {
            NodeUtils_1.NodeUtils.parentizeNode(propertyNode, parentNode);
        }
        return node;
    }
    transformPropertyNode(propertyNode) {
        const propertyKeyNode = propertyNode.key;
        if (RenamePropertiesTransformer_1.isValidPropertyNode(propertyNode, propertyKeyNode)) {
            propertyNode.key = this.renamePropertiesReplacer.replace(propertyKeyNode);
            propertyNode.shorthand = false;
        }
        return propertyNode;
    }
    transformPropertyDefinitionNode(propertyNode) {
        const propertyKeyNode = propertyNode.key;
        if (RenamePropertiesTransformer_1.isValidPropertyNode(propertyNode, propertyKeyNode)) {
            propertyNode.key = this.renamePropertiesReplacer.replace(propertyKeyNode);
        }
        return propertyNode;
    }
    transformMemberExpressionNode(memberExpressionNode) {
        const propertyKeyNode = memberExpressionNode.property;
        if (RenamePropertiesTransformer_1.isValidPropertyNode(memberExpressionNode, propertyKeyNode)) {
            memberExpressionNode.property = this.renamePropertiesReplacer.replace(propertyKeyNode);
        }
        return memberExpressionNode;
    }
    transformMethodDefinitionNode(methodDefinitionNode) {
        const propertyKeyNode = methodDefinitionNode.key;
        if (RenamePropertiesTransformer_1.isValidPropertyNode(methodDefinitionNode, propertyKeyNode)) {
            methodDefinitionNode.key = this.renamePropertiesReplacer.replace(propertyKeyNode);
        }
        return methodDefinitionNode;
    }
    analyzeAutoExcludedPropertyNames(node, parentNode) {
        if (!NodeGuards_1.NodeGuards.isLiteralNode(node) || !NodeLiteralUtils_1.NodeLiteralUtils.isStringLiteralNode(node)) {
            return;
        }
        if ((NodeGuards_1.NodeGuards.isPropertyNode(parentNode) && parentNode.key === node)
            || NodeGuards_1.NodeGuards.isMemberExpressionNode(parentNode) && parentNode.property === node
            || NodeGuards_1.NodeGuards.isMethodDefinitionNode(parentNode) && parentNode.key === node
            || NodeGuards_1.NodeGuards.isPropertyDefinitionNode(parentNode) && parentNode.key === node) {
            return;
        }
        this.renamePropertiesReplacer.excludePropertyName(node.value);
    }
};
RenamePropertiesTransformer = RenamePropertiesTransformer_1 = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRenamePropertiesReplacer)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(2, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [typeof (_a = typeof IRenamePropertiesReplacer_1.IRenamePropertiesReplacer !== "undefined" && IRenamePropertiesReplacer_1.IRenamePropertiesReplacer) === "function" ? _a : Object, typeof (_b = typeof IRandomGenerator_1.IRandomGenerator !== "undefined" && IRandomGenerator_1.IRandomGenerator) === "function" ? _b : Object, typeof (_c = typeof IOptions_1.IOptions !== "undefined" && IOptions_1.IOptions) === "function" ? _c : Object])
], RenamePropertiesTransformer);
exports.RenamePropertiesTransformer = RenamePropertiesTransformer;
