"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c, _d;
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScopeIdentifiersTransformer = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const estraverse = __importStar(require("@javascript-obfuscator/estraverse"));
const IIdentifierReplacer_1 = require("../../interfaces/node-transformers/rename-identifiers-transformers/replacer/IIdentifierReplacer");
const IOptions_1 = require("../../interfaces/options/IOptions");
const IRandomGenerator_1 = require("../../interfaces/utils/IRandomGenerator");
const IScopeIdentifiersTraverser_1 = require("../../interfaces/node/IScopeIdentifiersTraverser");
const NodeTransformationStage_1 = require("../../enums/node-transformers/NodeTransformationStage");
const AbstractNodeTransformer_1 = require("../AbstractNodeTransformer");
const NodeGuards_1 = require("../../node/NodeGuards");
const NodeMetadata_1 = require("../../node/NodeMetadata");
let ScopeIdentifiersTransformer = class ScopeIdentifiersTransformer extends AbstractNodeTransformer_1.AbstractNodeTransformer {
    constructor(identifierReplacer, randomGenerator, options, scopeIdentifiersTraverser) {
        super(randomGenerator, options);
        this.lexicalScopesWithObjectPatternWithoutDeclarationMap = new Map();
        this.identifierReplacer = identifierReplacer;
        this.scopeIdentifiersTraverser = scopeIdentifiersTraverser;
    }
    getVisitor(nodeTransformationStage) {
        switch (nodeTransformationStage) {
            case NodeTransformationStage_1.NodeTransformationStage.RenameIdentifiers:
                return {
                    enter: (node, parentNode) => {
                        if (parentNode && NodeGuards_1.NodeGuards.isProgramNode(node)) {
                            return this.transformNode(node, parentNode);
                        }
                    }
                };
            default:
                return null;
        }
    }
    transformNode(programNode, parentNode) {
        this.scopeIdentifiersTraverser.traverseScopeIdentifiers(programNode, parentNode, (data) => {
            const { isGlobalDeclaration, variable, variableLexicalScopeNode } = data;
            if (!this.options.renameGlobals && isGlobalDeclaration) {
                const isImportBindingOrCatchClauseIdentifier = variable.defs
                    .every((definition) => definition.type === 'ImportBinding'
                    || definition.type === 'CatchClause');
                if (!isImportBindingOrCatchClauseIdentifier) {
                    return;
                }
            }
            this.transformScopeVariableIdentifiers(variable, variableLexicalScopeNode, isGlobalDeclaration);
        });
        return programNode;
    }
    transformScopeVariableIdentifiers(variable, lexicalScopeNode, isGlobalDeclaration) {
        var _a;
        const firstIdentifier = (_a = variable.identifiers[0]) !== null && _a !== void 0 ? _a : null;
        if (!firstIdentifier) {
            return;
        }
        if (!this.isReplaceableIdentifierNode(firstIdentifier, lexicalScopeNode, variable)) {
            return;
        }
        this.storeIdentifierName(firstIdentifier, lexicalScopeNode, isGlobalDeclaration);
        this.replaceIdentifierName(firstIdentifier, lexicalScopeNode, variable);
    }
    storeIdentifierName(identifierNode, lexicalScopeNode, isGlobalDeclaration) {
        if (isGlobalDeclaration) {
            this.identifierReplacer.storeGlobalName(identifierNode, lexicalScopeNode);
        }
        else {
            this.identifierReplacer.storeLocalName(identifierNode, lexicalScopeNode);
        }
    }
    replaceIdentifierName(identifierNode, lexicalScopeNode, variable) {
        const newIdentifier = this.identifierReplacer
            .replace(identifierNode, lexicalScopeNode);
        variable.identifiers.forEach((identifier) => {
            identifier.name = newIdentifier.name;
        });
        variable.references.forEach((reference) => {
            reference.identifier.name = identifierNode.name;
        });
    }
    isReplaceableIdentifierNode(identifierNode, lexicalScopeNode, variable) {
        const parentNode = identifierNode.parentNode;
        return !!parentNode
            && !NodeMetadata_1.NodeMetadata.isIgnoredNode(identifierNode)
            && !this.isProhibitedPropertyIdentifierNode(identifierNode, parentNode)
            && !this.isProhibitedPropertyAssignmentPatternIdentifierNode(identifierNode, parentNode)
            && !this.isProhibitedClassDeclarationNameIdentifierNode(variable, identifierNode, parentNode)
            && !this.isProhibitedExportNamedClassDeclarationIdentifierNode(identifierNode, parentNode)
            && !this.isProhibitedExportNamedFunctionDeclarationIdentifierNode(identifierNode, parentNode)
            && !this.isProhibitedExportNamedVariableDeclarationIdentifierNode(identifierNode, parentNode)
            && !this.isProhibitedImportSpecifierNode(identifierNode, parentNode)
            && !this.isProhibitedVariableNameUsedInObjectPatternNode(variable, identifierNode, lexicalScopeNode)
            && !NodeGuards_1.NodeGuards.isLabelIdentifierNode(identifierNode, parentNode);
    }
    isProhibitedClassDeclarationNameIdentifierNode(variable, identifierNode, parentNode) {
        return NodeGuards_1.NodeGuards.isClassDeclarationNode(variable.scope.block)
            && NodeGuards_1.NodeGuards.isClassDeclarationNode(parentNode)
            && parentNode.id === identifierNode;
    }
    isProhibitedExportNamedClassDeclarationIdentifierNode(identifierNode, parentNode) {
        return NodeGuards_1.NodeGuards.isClassDeclarationNode(parentNode)
            && parentNode.id === identifierNode
            && !!parentNode.parentNode
            && NodeGuards_1.NodeGuards.isExportNamedDeclarationNode(parentNode.parentNode);
    }
    isProhibitedExportNamedFunctionDeclarationIdentifierNode(identifierNode, parentNode) {
        return NodeGuards_1.NodeGuards.isFunctionDeclarationNode(parentNode)
            && parentNode.id === identifierNode
            && !!parentNode.parentNode
            && NodeGuards_1.NodeGuards.isExportNamedDeclarationNode(parentNode.parentNode);
    }
    isProhibitedExportNamedVariableDeclarationIdentifierNode(identifierNode, parentNode) {
        return NodeGuards_1.NodeGuards.isVariableDeclaratorNode(parentNode)
            && parentNode.id === identifierNode
            && !!parentNode.parentNode
            && NodeGuards_1.NodeGuards.isVariableDeclarationNode(parentNode.parentNode)
            && !!parentNode.parentNode.parentNode
            && NodeGuards_1.NodeGuards.isExportNamedDeclarationNode(parentNode.parentNode.parentNode);
    }
    isProhibitedImportSpecifierNode(identifierNode, parentNode) {
        return NodeGuards_1.NodeGuards.isImportSpecifierNode(parentNode)
            && parentNode.imported.name === parentNode.local.name;
    }
    isProhibitedPropertyIdentifierNode(node, parentNode) {
        return NodeGuards_1.NodeGuards.isPropertyNode(parentNode)
            && !parentNode.computed
            && NodeGuards_1.NodeGuards.isIdentifierNode(parentNode.key)
            && NodeGuards_1.NodeGuards.isIdentifierNode(node)
            && parentNode.shorthand
            && parentNode.key.name === node.name;
    }
    isProhibitedPropertyAssignmentPatternIdentifierNode(node, parentNode) {
        return NodeGuards_1.NodeGuards.isAssignmentPatternNode(parentNode)
            && parentNode.left === node
            && !!parentNode.parentNode
            && NodeGuards_1.NodeGuards.isPropertyNode(parentNode.parentNode)
            && NodeGuards_1.NodeGuards.isIdentifierNode(parentNode.left)
            && NodeGuards_1.NodeGuards.isIdentifierNode(parentNode.parentNode.key)
            && parentNode.left.name === parentNode.parentNode.key.name;
    }
    isProhibitedVariableNameUsedInObjectPatternNode(variable, identifierNode, lexicalScopeNode) {
        let isLexicalScopeHasObjectPatternWithoutDeclaration = this.lexicalScopesWithObjectPatternWithoutDeclarationMap.get(lexicalScopeNode);
        if (isLexicalScopeHasObjectPatternWithoutDeclaration === false) {
            return false;
        }
        const hasVarDefinitions = variable.defs.some((definition) => definition.kind === 'var');
        if (!hasVarDefinitions) {
            return false;
        }
        let isProhibitedVariableDeclaration = false;
        estraverse.traverse(lexicalScopeNode, {
            enter: (node, parentNode) => {
                if (NodeGuards_1.NodeGuards.isObjectPatternNode(node)
                    && parentNode
                    && NodeGuards_1.NodeGuards.isAssignmentExpressionNode(parentNode)) {
                    isLexicalScopeHasObjectPatternWithoutDeclaration = true;
                    const properties = node.properties;
                    for (const property of properties) {
                        if (!NodeGuards_1.NodeGuards.isPropertyNode(property)) {
                            continue;
                        }
                        if (property.computed || !property.shorthand) {
                            continue;
                        }
                        if (!NodeGuards_1.NodeGuards.isIdentifierNode(property.key)) {
                            continue;
                        }
                        if (identifierNode.name !== property.key.name) {
                            continue;
                        }
                        isProhibitedVariableDeclaration = true;
                        return estraverse.VisitorOption.Break;
                    }
                }
            }
        });
        this.lexicalScopesWithObjectPatternWithoutDeclarationMap.set(lexicalScopeNode, isLexicalScopeHasObjectPatternWithoutDeclaration !== null && isLexicalScopeHasObjectPatternWithoutDeclaration !== void 0 ? isLexicalScopeHasObjectPatternWithoutDeclaration : false);
        return isProhibitedVariableDeclaration;
    }
};
ScopeIdentifiersTransformer = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IIdentifierReplacer)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(2, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __param(3, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IScopeIdentifiersTraverser)),
    __metadata("design:paramtypes", [typeof (_a = typeof IIdentifierReplacer_1.IIdentifierReplacer !== "undefined" && IIdentifierReplacer_1.IIdentifierReplacer) === "function" ? _a : Object, typeof (_b = typeof IRandomGenerator_1.IRandomGenerator !== "undefined" && IRandomGenerator_1.IRandomGenerator) === "function" ? _b : Object, typeof (_c = typeof IOptions_1.IOptions !== "undefined" && IOptions_1.IOptions) === "function" ? _c : Object, typeof (_d = typeof IScopeIdentifiersTraverser_1.IScopeIdentifiersTraverser !== "undefined" && IScopeIdentifiersTraverser_1.IScopeIdentifiersTraverser) === "function" ? _d : Object])
], ScopeIdentifiersTransformer);
exports.ScopeIdentifiersTransformer = ScopeIdentifiersTransformer;
