"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.DirectivePlacementTransformer = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const estraverse = __importStar(require("@javascript-obfuscator/estraverse"));
const IOptions_1 = require("../../interfaces/options/IOptions");
const IRandomGenerator_1 = require("../../interfaces/utils/IRandomGenerator");
const NodeTransformationStage_1 = require("../../enums/node-transformers/NodeTransformationStage");
const NodeTransformer_1 = require("../../enums/node-transformers/NodeTransformer");
const AbstractNodeTransformer_1 = require("../AbstractNodeTransformer");
const NodeAppender_1 = require("../../node/NodeAppender");
const NodeGuards_1 = require("../../node/NodeGuards");
const NodeUtils_1 = require("../../node/NodeUtils");
let DirectivePlacementTransformer = class DirectivePlacementTransformer extends AbstractNodeTransformer_1.AbstractNodeTransformer {
    constructor(randomGenerator, options) {
        super(randomGenerator, options);
        this.runAfter = [
            NodeTransformer_1.NodeTransformer.CustomCodeHelpersTransformer
        ];
        this.lexicalScopeDirectives = new WeakMap();
    }
    getVisitor(nodeTransformationStage) {
        switch (nodeTransformationStage) {
            case NodeTransformationStage_1.NodeTransformationStage.Preparing:
                return {
                    enter: (node, parentNode) => {
                        if (parentNode && NodeGuards_1.NodeGuards.isNodeWithLexicalScopeStatements(node, parentNode)) {
                            return this.analyzeNode(node, parentNode);
                        }
                    }
                };
            case NodeTransformationStage_1.NodeTransformationStage.Finalizing:
                return {
                    enter: (node, parentNode) => {
                        if (parentNode && NodeGuards_1.NodeGuards.isNodeWithLexicalScopeStatements(node, parentNode)) {
                            return this.transformNode(node, parentNode);
                        }
                    }
                };
            default:
                return null;
        }
    }
    analyzeNode(nodeWithLexicalScopeStatements, parentNode) {
        var _a;
        if (!NodeGuards_1.NodeGuards.isNodeWithLexicalScope(parentNode)) {
            return nodeWithLexicalScopeStatements;
        }
        const firstStatementNode = (_a = nodeWithLexicalScopeStatements.body[0]) !== null && _a !== void 0 ? _a : null;
        if (firstStatementNode && NodeGuards_1.NodeGuards.isDirectiveNode(firstStatementNode)) {
            this.lexicalScopeDirectives.set(parentNode, firstStatementNode);
        }
        return nodeWithLexicalScopeStatements;
    }
    transformNode(nodeWithLexicalScopeStatements, parentNode) {
        if (!NodeGuards_1.NodeGuards.isNodeWithLexicalScope(parentNode)) {
            return nodeWithLexicalScopeStatements;
        }
        const directiveNode = this.lexicalScopeDirectives.get(parentNode);
        if (directiveNode) {
            const newDirectiveNode = NodeUtils_1.NodeUtils.clone(directiveNode);
            NodeAppender_1.NodeAppender.prepend(nodeWithLexicalScopeStatements, [newDirectiveNode]);
            let isDirectiveNodeRemoved = false;
            estraverse.replace(nodeWithLexicalScopeStatements, {
                enter: (node) => {
                    if (isDirectiveNodeRemoved) {
                        return estraverse.VisitorOption.Break;
                    }
                    if (node === directiveNode) {
                        isDirectiveNodeRemoved = true;
                        return estraverse.VisitorOption.Remove;
                    }
                }
            });
        }
        return nodeWithLexicalScopeStatements;
    }
};
DirectivePlacementTransformer = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [typeof (_a = typeof IRandomGenerator_1.IRandomGenerator !== "undefined" && IRandomGenerator_1.IRandomGenerator) === "function" ? _a : Object, typeof (_b = typeof IOptions_1.IOptions !== "undefined" && IOptions_1.IOptions) === "function" ? _b : Object])
], DirectivePlacementTransformer);
exports.DirectivePlacementTransformer = DirectivePlacementTransformer;
