"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var AbstractCustomCodeHelper_1, _a, _b, _c, _d, _e;
Object.defineProperty(exports, "__esModule", { value: true });
exports.AbstractCustomCodeHelper = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../container/ServiceIdentifiers");
const TIdentifierNamesGeneratorFactory_1 = require("../types/container/generators/TIdentifierNamesGeneratorFactory");
const ICustomCodeHelperFormatter_1 = require("../interfaces/custom-code-helpers/ICustomCodeHelperFormatter");
const ICustomCodeHelperObfuscator_1 = require("../interfaces/custom-code-helpers/ICustomCodeHelperObfuscator");
const IOptions_1 = require("../interfaces/options/IOptions");
const IRandomGenerator_1 = require("../interfaces/utils/IRandomGenerator");
const GlobalVariableTemplate1_1 = require("./common/templates/GlobalVariableTemplate1");
const GlobalVariableTemplate2_1 = require("./common/templates/GlobalVariableTemplate2");
let AbstractCustomCodeHelper = AbstractCustomCodeHelper_1 = class AbstractCustomCodeHelper {
    constructor(identifierNamesGeneratorFactory, customCodeHelperFormatter, customCodeHelperObfuscator, randomGenerator, options) {
        this.cachedNode = null;
        this.identifierNamesGenerator = identifierNamesGeneratorFactory(options);
        this.customCodeHelperFormatter = customCodeHelperFormatter;
        this.customCodeHelperObfuscator = customCodeHelperObfuscator;
        this.randomGenerator = randomGenerator;
        this.options = options;
    }
    getNode() {
        if (!this.cachedNode) {
            const codeHelperTemplate = this.getCodeHelperTemplate();
            this.cachedNode = this.customCodeHelperFormatter.formatStructure(this.getNodeStructure(codeHelperTemplate));
        }
        return this.cachedNode;
    }
    getGlobalVariableTemplate() {
        return this.randomGenerator
            .getRandomGenerator()
            .pickone(AbstractCustomCodeHelper_1.globalVariableTemplateFunctions);
    }
    getCodeHelperTemplate() {
        return '';
    }
};
AbstractCustomCodeHelper.globalVariableTemplateFunctions = [
    (0, GlobalVariableTemplate1_1.GlobalVariableTemplate1)(),
    (0, GlobalVariableTemplate2_1.GlobalVariableTemplate2)()
];
AbstractCustomCodeHelper = AbstractCustomCodeHelper_1 = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IIdentifierNamesGenerator)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.ICustomCodeHelperFormatter)),
    __param(2, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.ICustomCodeHelperObfuscator)),
    __param(3, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(4, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [typeof (_a = typeof TIdentifierNamesGeneratorFactory_1.TIdentifierNamesGeneratorFactory !== "undefined" && TIdentifierNamesGeneratorFactory_1.TIdentifierNamesGeneratorFactory) === "function" ? _a : Object, typeof (_b = typeof ICustomCodeHelperFormatter_1.ICustomCodeHelperFormatter !== "undefined" && ICustomCodeHelperFormatter_1.ICustomCodeHelperFormatter) === "function" ? _b : Object, typeof (_c = typeof ICustomCodeHelperObfuscator_1.ICustomCodeHelperObfuscator !== "undefined" && ICustomCodeHelperObfuscator_1.ICustomCodeHelperObfuscator) === "function" ? _c : Object, typeof (_d = typeof IRandomGenerator_1.IRandomGenerator !== "undefined" && IRandomGenerator_1.IRandomGenerator) === "function" ? _d : Object, typeof (_e = typeof IOptions_1.IOptions !== "undefined" && IOptions_1.IOptions) === "function" ? _e : Object])
], AbstractCustomCodeHelper);
exports.AbstractCustomCodeHelper = AbstractCustomCodeHelper;
