"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.IfStatementSimplifyTransformer = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const IOptions_1 = require("../../interfaces/options/IOptions");
const IRandomGenerator_1 = require("../../interfaces/utils/IRandomGenerator");
const NodeTransformationStage_1 = require("../../enums/node-transformers/NodeTransformationStage");
const AbstractStatementSimplifyTransformer_1 = require("./AbstractStatementSimplifyTransformer");
const NodeGuards_1 = require("../../node/NodeGuards");
const NodeFactory_1 = require("../../node/NodeFactory");
const NodeUtils_1 = require("../../node/NodeUtils");
let IfStatementSimplifyTransformer = class IfStatementSimplifyTransformer extends AbstractStatementSimplifyTransformer_1.AbstractStatementSimplifyTransformer {
    constructor(randomGenerator, options) {
        super(randomGenerator, options);
    }
    getVisitor(nodeTransformationStage) {
        switch (nodeTransformationStage) {
            case NodeTransformationStage_1.NodeTransformationStage.Simplifying:
                return {
                    leave: (node, parentNode) => {
                        if (parentNode && NodeGuards_1.NodeGuards.isIfStatementNode(node)) {
                            return this.transformNode(node, parentNode);
                        }
                    }
                };
            default:
                return null;
        }
    }
    transformNode(ifStatementNode, parentNode) {
        const consequentSimplifyData = this.getStatementSimplifyData(ifStatementNode.consequent);
        if (!consequentSimplifyData) {
            return ifStatementNode;
        }
        let transformedNode;
        if (!ifStatementNode.alternate) {
            transformedNode = this.getConsequentNode(ifStatementNode, consequentSimplifyData);
        }
        else {
            const alternateSimplifyData = this.getStatementSimplifyData(ifStatementNode.alternate);
            if (!alternateSimplifyData) {
                return ifStatementNode;
            }
            transformedNode = this.getConsequentAndAlternateNode(ifStatementNode, consequentSimplifyData, alternateSimplifyData);
        }
        return NodeUtils_1.NodeUtils.parentizeNode(transformedNode, parentNode);
    }
    getConsequentNode(ifStatementNode, consequentSimplifyData) {
        if (consequentSimplifyData.leadingStatements.length
            || !consequentSimplifyData.trailingStatement) {
            return NodeFactory_1.NodeFactory.ifStatementNode(ifStatementNode.test, this.getPartialStatement(consequentSimplifyData));
        }
        if (consequentSimplifyData.hasReturnStatement) {
            return NodeFactory_1.NodeFactory.ifStatementNode(ifStatementNode.test, consequentSimplifyData.trailingStatement.statement);
        }
        return NodeFactory_1.NodeFactory.expressionStatementNode(NodeFactory_1.NodeFactory.logicalExpressionNode('&&', ifStatementNode.test, consequentSimplifyData.trailingStatement.expression));
    }
    getConsequentAndAlternateNode(ifStatementNode, consequentSimplifyData, alternateSimplifyData) {
        if (consequentSimplifyData.leadingStatements.length
            || alternateSimplifyData.leadingStatements.length
            || !consequentSimplifyData.trailingStatement
            || !alternateSimplifyData.trailingStatement) {
            return NodeFactory_1.NodeFactory.ifStatementNode(ifStatementNode.test, this.getPartialStatement(consequentSimplifyData), this.getPartialStatement(alternateSimplifyData));
        }
        if (consequentSimplifyData.hasReturnStatement && alternateSimplifyData.hasReturnStatement) {
            return NodeFactory_1.NodeFactory.returnStatementNode(NodeFactory_1.NodeFactory.conditionalExpressionNode(ifStatementNode.test, consequentSimplifyData.trailingStatement.expression, alternateSimplifyData.trailingStatement.expression));
        }
        if (consequentSimplifyData.hasReturnStatement || alternateSimplifyData.hasReturnStatement) {
            return NodeFactory_1.NodeFactory.ifStatementNode(ifStatementNode.test, consequentSimplifyData.trailingStatement.statement, alternateSimplifyData.trailingStatement.statement);
        }
        return NodeFactory_1.NodeFactory.expressionStatementNode(NodeFactory_1.NodeFactory.conditionalExpressionNode(ifStatementNode.test, consequentSimplifyData.trailingStatement.expression, alternateSimplifyData.trailingStatement.expression));
    }
    getPartialStatement(statementSimplifyData) {
        const partialStatement = super.getPartialStatement(statementSimplifyData);
        if (!NodeGuards_1.NodeGuards.isBlockStatementNode(partialStatement)) {
            return partialStatement;
        }
        return partialStatement.body.length === 1
            && !this.isProhibitedSingleStatementForIfStatementBranch(partialStatement.body[0])
            ? partialStatement.body[0]
            : partialStatement;
    }
    isProhibitedSingleStatementForIfStatementBranch(statement) {
        return NodeGuards_1.NodeGuards.isFunctionDeclarationNode(statement)
            || NodeGuards_1.NodeGuards.isIfStatementNode(statement)
            || NodeGuards_1.NodeGuards.isNodeWithSingleStatementBody(statement)
            || (NodeGuards_1.NodeGuards.isVariableDeclarationNode(statement) && statement.kind !== 'var');
    }
};
IfStatementSimplifyTransformer = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [typeof (_a = typeof IRandomGenerator_1.IRandomGenerator !== "undefined" && IRandomGenerator_1.IRandomGenerator) === "function" ? _a : Object, typeof (_b = typeof IOptions_1.IOptions !== "undefined" && IOptions_1.IOptions) === "function" ? _b : Object])
], IfStatementSimplifyTransformer);
exports.IfStatementSimplifyTransformer = IfStatementSimplifyTransformer;
