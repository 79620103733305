"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.controlFlowTransformersModule = void 0;
const InversifyContainerFacade_1 = require("../../InversifyContainerFacade");
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../ServiceIdentifiers");
const ControlFlowReplacer_1 = require("../../../enums/node-transformers/control-flow-transformers/control-flow-replacers/ControlFlowReplacer");
const NodeTransformer_1 = require("../../../enums/node-transformers/NodeTransformer");
const BinaryExpressionControlFlowReplacer_1 = require("../../../node-transformers/control-flow-transformers/control-flow-replacers/BinaryExpressionControlFlowReplacer");
const BlockStatementControlFlowTransformer_1 = require("../../../node-transformers/control-flow-transformers/BlockStatementControlFlowTransformer");
const CallExpressionControlFlowReplacer_1 = require("../../../node-transformers/control-flow-transformers/control-flow-replacers/CallExpressionControlFlowReplacer");
const FunctionControlFlowTransformer_1 = require("../../../node-transformers/control-flow-transformers/FunctionControlFlowTransformer");
const LogicalExpressionControlFlowReplacer_1 = require("../../../node-transformers/control-flow-transformers/control-flow-replacers/LogicalExpressionControlFlowReplacer");
const StringLiteralControlFlowReplacer_1 = require("../../../node-transformers/control-flow-transformers/control-flow-replacers/StringLiteralControlFlowReplacer");
exports.controlFlowTransformersModule = new inversify_1.ContainerModule((bind) => {
    bind(ServiceIdentifiers_1.ServiceIdentifiers.INodeTransformer)
        .to(BlockStatementControlFlowTransformer_1.BlockStatementControlFlowTransformer)
        .whenTargetNamed(NodeTransformer_1.NodeTransformer.BlockStatementControlFlowTransformer);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.INodeTransformer)
        .to(FunctionControlFlowTransformer_1.FunctionControlFlowTransformer)
        .whenTargetNamed(NodeTransformer_1.NodeTransformer.FunctionControlFlowTransformer);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.IControlFlowReplacer)
        .to(BinaryExpressionControlFlowReplacer_1.BinaryExpressionControlFlowReplacer)
        .whenTargetNamed(ControlFlowReplacer_1.ControlFlowReplacer.BinaryExpressionControlFlowReplacer);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.IControlFlowReplacer)
        .to(CallExpressionControlFlowReplacer_1.CallExpressionControlFlowReplacer)
        .whenTargetNamed(ControlFlowReplacer_1.ControlFlowReplacer.CallExpressionControlFlowReplacer);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.IControlFlowReplacer)
        .to(LogicalExpressionControlFlowReplacer_1.LogicalExpressionControlFlowReplacer)
        .whenTargetNamed(ControlFlowReplacer_1.ControlFlowReplacer.LogicalExpressionControlFlowReplacer);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.IControlFlowReplacer)
        .to(StringLiteralControlFlowReplacer_1.StringLiteralControlFlowReplacer)
        .whenTargetNamed(ControlFlowReplacer_1.ControlFlowReplacer.StringLiteralControlFlowReplacer);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IControlFlowReplacer)
        .toFactory(InversifyContainerFacade_1.InversifyContainerFacade
        .getCacheFactory(ServiceIdentifiers_1.ServiceIdentifiers.IControlFlowReplacer));
});
