"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DebugProtectionFunctionTemplate = void 0;
function DebugProtectionFunctionTemplate() {
    return `
        function {debugProtectionFunctionName} (ret) {
            function debuggerProtection (counter) {
            
                {debuggerTemplate}
                
                debuggerProtection(++counter);
            }
            
            try {
                if (ret) {
                    return debuggerProtection;
                } else {
                    debuggerProtection(0);
                }
            } catch (y) {}
        }
    `;
}
exports.DebugProtectionFunctionTemplate = DebugProtectionFunctionTemplate;
