"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var CallsGraphAnalyzer_1, _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.CallsGraphAnalyzer = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const estraverse = __importStar(require("@javascript-obfuscator/estraverse"));
const TCalleeDataExtractorFactory_1 = require("../../types/container/calls-graph-analyzer/TCalleeDataExtractorFactory");
const CalleeDataExtractor_1 = require("../../enums/analyzers/calls-graph-analyzer/CalleeDataExtractor");
const NodeGuards_1 = require("../../node/NodeGuards");
const NodeStatementUtils_1 = require("../../node/NodeStatementUtils");
let CallsGraphAnalyzer = CallsGraphAnalyzer_1 = class CallsGraphAnalyzer {
    constructor(calleeDataExtractorFactory) {
        this.calleeDataExtractorFactory = calleeDataExtractorFactory;
    }
    static getLimitIndex(blockScopeBodyLength) {
        const lastIndex = blockScopeBodyLength - 1;
        const limitThresholdActivationIndex = CallsGraphAnalyzer_1.limitThresholdActivationLength - 1;
        let limitIndex = lastIndex;
        if (lastIndex > limitThresholdActivationIndex) {
            limitIndex = Math.round(limitThresholdActivationIndex + (lastIndex * CallsGraphAnalyzer_1.limitThreshold));
            if (limitIndex > lastIndex) {
                limitIndex = lastIndex;
            }
        }
        return limitIndex;
    }
    analyze(astTree) {
        return this.analyzeRecursive(astTree.body);
    }
    analyzeRecursive(blockScopeBody) {
        const limitIndex = CallsGraphAnalyzer_1.getLimitIndex(blockScopeBody.length);
        const callsGraphData = [];
        const blockScopeBodyLength = blockScopeBody.length;
        for (let index = 0; index < blockScopeBodyLength; index++) {
            if (index > limitIndex) {
                break;
            }
            const blockScopeBodyNode = blockScopeBody[index];
            estraverse.traverse(blockScopeBodyNode, {
                enter: (node) => {
                    if (!NodeGuards_1.NodeGuards.isCallExpressionNode(node)) {
                        return;
                    }
                    if (blockScopeBodyNode.parentNode !== NodeStatementUtils_1.NodeStatementUtils.getParentNodeWithStatements(node)) {
                        return estraverse.VisitorOption.Skip;
                    }
                    this.analyzeCallExpressionNode(callsGraphData, blockScopeBody, node);
                }
            });
        }
        return callsGraphData;
    }
    analyzeCallExpressionNode(callsGraphData, blockScopeBody, callExpressionNode) {
        CallsGraphAnalyzer_1.calleeDataExtractorsList.forEach((calleeDataExtractorName) => {
            const calleeData = this.calleeDataExtractorFactory(calleeDataExtractorName)
                .extract(blockScopeBody, callExpressionNode.callee);
            if (!calleeData) {
                return;
            }
            callsGraphData.push({
                ...calleeData,
                callsGraph: this.analyzeRecursive(calleeData.callee.body)
            });
        });
    }
};
CallsGraphAnalyzer.calleeDataExtractorsList = [
    CalleeDataExtractor_1.CalleeDataExtractor.FunctionDeclarationCalleeDataExtractor,
    CalleeDataExtractor_1.CalleeDataExtractor.FunctionExpressionCalleeDataExtractor,
    CalleeDataExtractor_1.CalleeDataExtractor.ObjectExpressionCalleeDataExtractor
];
CallsGraphAnalyzer.limitThresholdActivationLength = 25;
CallsGraphAnalyzer.limitThreshold = 0.002;
CallsGraphAnalyzer = CallsGraphAnalyzer_1 = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__ICalleeDataExtractor)),
    __metadata("design:paramtypes", [typeof (_a = typeof TCalleeDataExtractorFactory_1.TCalleeDataExtractorFactory !== "undefined" && TCalleeDataExtractorFactory_1.TCalleeDataExtractorFactory) === "function" ? _a : Object])
], CallsGraphAnalyzer);
exports.CallsGraphAnalyzer = CallsGraphAnalyzer;
