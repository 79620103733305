"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var DictionaryIdentifierNamesGenerator_1, _a, _b, _c;
Object.defineProperty(exports, "__esModule", { value: true });
exports.DictionaryIdentifierNamesGenerator = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const IArrayUtils_1 = require("../../interfaces/utils/IArrayUtils");
const IOptions_1 = require("../../interfaces/options/IOptions");
const IRandomGenerator_1 = require("../../interfaces/utils/IRandomGenerator");
const AbstractIdentifierNamesGenerator_1 = require("./AbstractIdentifierNamesGenerator");
const NodeLexicalScopeUtils_1 = require("../../node/NodeLexicalScopeUtils");
let DictionaryIdentifierNamesGenerator = DictionaryIdentifierNamesGenerator_1 = class DictionaryIdentifierNamesGenerator extends AbstractIdentifierNamesGenerator_1.AbstractIdentifierNamesGenerator {
    constructor(randomGenerator, options, arrayUtils) {
        super(randomGenerator, options);
        this.arrayUtils = arrayUtils;
        this.identifierNamesSet = new Set(this.getInitialIdentifierNames(this.options.identifiersDictionary));
        this.identifiersIterator = this.identifierNamesSet.values();
    }
    static incrementIdentifierName(identifierName) {
        let newIdentifierName = '';
        let isSuccess = false;
        for (const character of identifierName) {
            if (!isSuccess && character === character.toUpperCase()) {
                newIdentifierName += character.toLowerCase();
            }
            else if (!isSuccess && character === character.toLowerCase()) {
                newIdentifierName += character.toUpperCase();
                isSuccess = true;
            }
            else {
                newIdentifierName += character;
            }
        }
        if (isSuccess) {
            return newIdentifierName;
        }
        return null;
    }
    generateNext() {
        const identifierName = this.generateNewDictionaryName();
        this.preserveName(identifierName);
        return identifierName;
    }
    generateForGlobalScope() {
        const prefix = this.options.identifiersPrefix ?
            `${this.options.identifiersPrefix}`
            : '';
        const identifierName = this.generateNewDictionaryName();
        const identifierNameWithPrefix = `${prefix}${identifierName}`;
        if (!this.isValidIdentifierName(identifierNameWithPrefix)) {
            return this.generateForGlobalScope();
        }
        this.preserveName(identifierNameWithPrefix);
        return identifierNameWithPrefix;
    }
    generateForLexicalScope(lexicalScopeNode) {
        const lexicalScopes = [
            lexicalScopeNode,
            ...NodeLexicalScopeUtils_1.NodeLexicalScopeUtils.getLexicalScopes(lexicalScopeNode)
        ];
        const identifierName = this.generateNewDictionaryName();
        if (!this.isValidIdentifierNameInLexicalScopes(identifierName, lexicalScopes)) {
            return this.generateForLexicalScope(lexicalScopeNode);
        }
        this.preserveNameForLexicalScope(identifierName, lexicalScopeNode);
        return identifierName;
    }
    generateNewDictionaryName() {
        if (!this.identifierNamesSet.size) {
            throw new Error('Too many identifiers in the code, add more words to identifiers dictionary');
        }
        const iteratorResult = this.identifiersIterator.next();
        if (!iteratorResult.done) {
            const identifierName = iteratorResult.value;
            if (!this.isValidIdentifierName(identifierName)) {
                return this.generateNewDictionaryName();
            }
            return iteratorResult.value;
        }
        this.identifierNamesSet = new Set(this.getIncrementedIdentifierNames([...this.identifierNamesSet]));
        this.identifiersIterator = this.identifierNamesSet.values();
        return this.generateNewDictionaryName();
    }
    getInitialIdentifierNames(identifierNames) {
        const formattedIdentifierNames = identifierNames
            .filter(Boolean)
            .map((identifierName) => identifierName.toLowerCase());
        return this.arrayUtils.shuffle(formattedIdentifierNames);
    }
    getIncrementedIdentifierNames(identifierNames) {
        const formattedIdentifierNames = [];
        for (const identifierName of identifierNames) {
            const newIdentifierName = DictionaryIdentifierNamesGenerator_1
                .incrementIdentifierName(identifierName);
            if (newIdentifierName) {
                formattedIdentifierNames.push(newIdentifierName);
            }
        }
        return this.arrayUtils.shuffle(formattedIdentifierNames);
    }
};
DictionaryIdentifierNamesGenerator = DictionaryIdentifierNamesGenerator_1 = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __param(2, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IArrayUtils)),
    __metadata("design:paramtypes", [typeof (_a = typeof IRandomGenerator_1.IRandomGenerator !== "undefined" && IRandomGenerator_1.IRandomGenerator) === "function" ? _a : Object, typeof (_b = typeof IOptions_1.IOptions !== "undefined" && IOptions_1.IOptions) === "function" ? _b : Object, typeof (_c = typeof IArrayUtils_1.IArrayUtils !== "undefined" && IArrayUtils_1.IArrayUtils) === "function" ? _c : Object])
], DictionaryIdentifierNamesGenerator);
exports.DictionaryIdentifierNamesGenerator = DictionaryIdentifierNamesGenerator;
