"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c, _d;
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScopeThroughIdentifiersTransformer = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const IOptions_1 = require("../../interfaces/options/IOptions");
const IRandomGenerator_1 = require("../../interfaces/utils/IRandomGenerator");
const IScopeIdentifiersTraverser_1 = require("../../interfaces/node/IScopeIdentifiersTraverser");
const IThroughIdentifierReplacer_1 = require("../../interfaces/node-transformers/rename-identifiers-transformers/replacer/IThroughIdentifierReplacer");
const NodeTransformationStage_1 = require("../../enums/node-transformers/NodeTransformationStage");
const AbstractNodeTransformer_1 = require("../AbstractNodeTransformer");
const NodeGuards_1 = require("../../node/NodeGuards");
let ScopeThroughIdentifiersTransformer = class ScopeThroughIdentifiersTransformer extends AbstractNodeTransformer_1.AbstractNodeTransformer {
    constructor(throughIdentifierReplacer, scopeIdentifiersTraverser, randomGenerator, options) {
        super(randomGenerator, options);
        this.throughIdentifierReplacer = throughIdentifierReplacer;
        this.scopeIdentifiersTraverser = scopeIdentifiersTraverser;
    }
    getVisitor(nodeTransformationStage) {
        switch (nodeTransformationStage) {
            case NodeTransformationStage_1.NodeTransformationStage.RenameIdentifiers:
                return {
                    enter: (node, parentNode) => {
                        if (parentNode && NodeGuards_1.NodeGuards.isProgramNode(node)) {
                            return this.transformNode(node, parentNode);
                        }
                    }
                };
            default:
                return null;
        }
    }
    transformNode(programNode, parentNode) {
        this.scopeIdentifiersTraverser.traverseScopeThroughIdentifiers(programNode, parentNode, (data) => {
            const { reference, variableLexicalScopeNode } = data;
            this.transformScopeThroughIdentifiers(reference, variableLexicalScopeNode);
        });
        return programNode;
    }
    transformScopeThroughIdentifiers(reference, lexicalScopeNode) {
        if (reference.resolved) {
            return;
        }
        this.replaceIdentifierName(reference);
    }
    replaceIdentifierName(reference) {
        const identifier = reference.identifier;
        const newIdentifier = this.throughIdentifierReplacer.replace(identifier);
        reference.identifier.name = newIdentifier.name;
    }
};
ScopeThroughIdentifiersTransformer = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IThroughIdentifierReplacer)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IScopeIdentifiersTraverser)),
    __param(2, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(3, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [typeof (_a = typeof IThroughIdentifierReplacer_1.IThroughIdentifierReplacer !== "undefined" && IThroughIdentifierReplacer_1.IThroughIdentifierReplacer) === "function" ? _a : Object, typeof (_b = typeof IScopeIdentifiersTraverser_1.IScopeIdentifiersTraverser !== "undefined" && IScopeIdentifiersTraverser_1.IScopeIdentifiersTraverser) === "function" ? _b : Object, typeof (_c = typeof IRandomGenerator_1.IRandomGenerator !== "undefined" && IRandomGenerator_1.IRandomGenerator) === "function" ? _c : Object, typeof (_d = typeof IOptions_1.IOptions !== "undefined" && IOptions_1.IOptions) === "function" ? _d : Object])
], ScopeThroughIdentifiersTransformer);
exports.ScopeThroughIdentifiersTransformer = ScopeThroughIdentifiersTransformer;
