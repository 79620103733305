"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NO_ADDITIONAL_NODES_PRESET = void 0;
const IdentifierNamesGenerator_1 = require("../../enums/generators/identifier-names-generators/IdentifierNamesGenerator");
const ObfuscationTarget_1 = require("../../enums/ObfuscationTarget");
const RenamePropertiesMode_1 = require("../../enums/node-transformers/rename-properties-transformers/RenamePropertiesMode");
const SourceMapMode_1 = require("../../enums/source-map/SourceMapMode");
const SourceMapSourcesMode_1 = require("../../enums/source-map/SourceMapSourcesMode");
const StringArrayEncoding_1 = require("../../enums/node-transformers/string-array-transformers/StringArrayEncoding");
const StringArrayWrappersType_1 = require("../../enums/node-transformers/string-array-transformers/StringArrayWrappersType");
const StringArrayIndexesType_1 = require("../../enums/node-transformers/string-array-transformers/StringArrayIndexesType");
exports.NO_ADDITIONAL_NODES_PRESET = Object.freeze({
    compact: true,
    controlFlowFlattening: false,
    controlFlowFlatteningThreshold: 0,
    deadCodeInjection: false,
    deadCodeInjectionThreshold: 0,
    debugProtection: false,
    debugProtectionInterval: false,
    disableConsoleOutput: false,
    domainLock: [],
    domainLockRedirectUrl: 'about:blank',
    exclude: [],
    forceTransformStrings: [],
    identifierNamesGenerator: IdentifierNamesGenerator_1.IdentifierNamesGenerator.HexadecimalIdentifierNamesGenerator,
    identifiersPrefix: '',
    identifiersDictionary: [],
    ignoreImports: false,
    inputFileName: '',
    log: false,
    numbersToExpressions: false,
    renameGlobals: false,
    renameProperties: false,
    renamePropertiesMode: RenamePropertiesMode_1.RenamePropertiesMode.Safe,
    reservedNames: [],
    reservedStrings: [],
    stringArrayRotate: false,
    seed: 0,
    selfDefending: false,
    stringArrayShuffle: false,
    simplify: false,
    sourceMap: false,
    sourceMapBaseUrl: '',
    sourceMapFileName: '',
    sourceMapMode: SourceMapMode_1.SourceMapMode.Separate,
    sourceMapSourcesMode: SourceMapSourcesMode_1.SourceMapSourcesMode.SourcesContent,
    splitStrings: false,
    splitStringsChunkLength: 0,
    stringArray: false,
    stringArrayEncoding: [
        StringArrayEncoding_1.StringArrayEncoding.None
    ],
    stringArrayIndexesType: [
        StringArrayIndexesType_1.StringArrayIndexesType.HexadecimalNumber
    ],
    stringArrayIndexShift: false,
    stringArrayWrappersChainedCalls: false,
    stringArrayWrappersCount: 0,
    stringArrayWrappersParametersMaxCount: 2,
    stringArrayWrappersType: StringArrayWrappersType_1.StringArrayWrappersType.Variable,
    stringArrayThreshold: 0,
    target: ObfuscationTarget_1.ObfuscationTarget.Browser,
    transformObjectKeys: false,
    unicodeEscapeSequence: false
});
