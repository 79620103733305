"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DebugProtectionFunctionIntervalTemplate = void 0;
function DebugProtectionFunctionIntervalTemplate() {
    return `
        setInterval(function () {
            {debugProtectionFunctionName}();
        }, 4000);
    `;
}
exports.DebugProtectionFunctionIntervalTemplate = DebugProtectionFunctionIntervalTemplate;
