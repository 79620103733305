"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var BlockStatementControlFlowTransformer_1, _a, _b, _c, _d;
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlockStatementControlFlowTransformer = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const estraverse = __importStar(require("@javascript-obfuscator/estraverse"));
const TControlFlowCustomNodeFactory_1 = require("../../types/container/custom-nodes/TControlFlowCustomNodeFactory");
const IArrayUtils_1 = require("../../interfaces/utils/IArrayUtils");
const IOptions_1 = require("../../interfaces/options/IOptions");
const IRandomGenerator_1 = require("../../interfaces/utils/IRandomGenerator");
const ControlFlowCustomNode_1 = require("../../enums/custom-nodes/ControlFlowCustomNode");
const NodeTransformationStage_1 = require("../../enums/node-transformers/NodeTransformationStage");
const AbstractNodeTransformer_1 = require("../AbstractNodeTransformer");
const NodeGuards_1 = require("../../node/NodeGuards");
const NodeUtils_1 = require("../../node/NodeUtils");
let BlockStatementControlFlowTransformer = BlockStatementControlFlowTransformer_1 = class BlockStatementControlFlowTransformer extends AbstractNodeTransformer_1.AbstractNodeTransformer {
    constructor(controlFlowCustomNodeFactory, arrayUtils, randomGenerator, options) {
        super(randomGenerator, options);
        this.controlFlowCustomNodeFactory = controlFlowCustomNodeFactory;
        this.arrayUtils = arrayUtils;
    }
    static isProhibitedStatementNode(node) {
        const isBreakOrContinueStatement = NodeGuards_1.NodeGuards.isBreakStatementNode(node)
            || NodeGuards_1.NodeGuards.isContinueStatementNode(node);
        const isVariableDeclarationWithLetOrConstKind = NodeGuards_1.NodeGuards.isVariableDeclarationNode(node)
            && (node.kind === 'const' || node.kind === 'let');
        const isClassDeclaration = NodeGuards_1.NodeGuards.isClassDeclarationNode(node);
        return NodeGuards_1.NodeGuards.isFunctionDeclarationNode(node)
            || isBreakOrContinueStatement
            || isVariableDeclarationWithLetOrConstKind
            || isClassDeclaration;
    }
    static canTransformBlockStatementNode(blockStatementNode) {
        let canTransform = true;
        estraverse.traverse(blockStatementNode, {
            enter: (node) => {
                if (NodeGuards_1.NodeGuards.isWhileStatementNode(node)) {
                    return estraverse.VisitorOption.Skip;
                }
                if (BlockStatementControlFlowTransformer_1.isProhibitedStatementNode(node)) {
                    canTransform = false;
                }
            }
        });
        if (blockStatementNode.body.length <= 4) {
            canTransform = false;
        }
        return canTransform;
    }
    getVisitor(nodeTransformationStage) {
        switch (nodeTransformationStage) {
            case NodeTransformationStage_1.NodeTransformationStage.ControlFlowFlattening:
                return {
                    leave: (node, parentNode) => {
                        if (parentNode && NodeGuards_1.NodeGuards.isBlockStatementNode(node)) {
                            return this.transformNode(node, parentNode);
                        }
                    }
                };
            default:
                return null;
        }
    }
    transformNode(blockStatementNode, parentNode) {
        if (this.randomGenerator.getMathRandom() > this.options.controlFlowFlatteningThreshold ||
            !BlockStatementControlFlowTransformer_1.canTransformBlockStatementNode(blockStatementNode)) {
            return blockStatementNode;
        }
        const blockStatementBody = blockStatementNode.body;
        const originalKeys = this.arrayUtils.createWithRange(blockStatementBody.length);
        const shuffledKeys = this.arrayUtils.shuffle(originalKeys);
        const originalKeysIndexesInShuffledArray = originalKeys.map((key) => shuffledKeys.indexOf(key));
        const blockStatementControlFlowFlatteningCustomNode = this.controlFlowCustomNodeFactory(ControlFlowCustomNode_1.ControlFlowCustomNode.BlockStatementControlFlowFlatteningNode);
        blockStatementControlFlowFlatteningCustomNode.initialize(blockStatementBody, shuffledKeys, originalKeysIndexesInShuffledArray);
        const newBlockStatementNode = blockStatementControlFlowFlatteningCustomNode.getNode()[0];
        NodeUtils_1.NodeUtils.parentizeNode(newBlockStatementNode, parentNode);
        return newBlockStatementNode;
    }
};
BlockStatementControlFlowTransformer = BlockStatementControlFlowTransformer_1 = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IControlFlowCustomNode)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IArrayUtils)),
    __param(2, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(3, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [typeof (_a = typeof TControlFlowCustomNodeFactory_1.TControlFlowCustomNodeFactory !== "undefined" && TControlFlowCustomNodeFactory_1.TControlFlowCustomNodeFactory) === "function" ? _a : Object, typeof (_b = typeof IArrayUtils_1.IArrayUtils !== "undefined" && IArrayUtils_1.IArrayUtils) === "function" ? _b : Object, typeof (_c = typeof IRandomGenerator_1.IRandomGenerator !== "undefined" && IRandomGenerator_1.IRandomGenerator) === "function" ? _c : Object, typeof (_d = typeof IOptions_1.IOptions !== "undefined" && IOptions_1.IOptions) === "function" ? _d : Object])
], BlockStatementControlFlowTransformer);
exports.BlockStatementControlFlowTransformer = BlockStatementControlFlowTransformer;
