"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j;
Object.defineProperty(exports, "__esModule", { value: true });
exports.StringArrayScopeCallsWrapperVariableNode = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const TIdentifierNamesGeneratorFactory_1 = require("../../types/container/generators/TIdentifierNamesGeneratorFactory");
const TStringArrayIndexNodeFactory_1 = require("../../types/container/custom-nodes/string-array-index-nodes/TStringArrayIndexNodeFactory");
const IArrayUtils_1 = require("../../interfaces/utils/IArrayUtils");
const ICustomCodeHelperFormatter_1 = require("../../interfaces/custom-code-helpers/ICustomCodeHelperFormatter");
const IOptions_1 = require("../../interfaces/options/IOptions");
const IRandomGenerator_1 = require("../../interfaces/utils/IRandomGenerator");
const IStringArrayScopeCallsWrapperData_1 = require("../../interfaces/node-transformers/string-array-transformers/IStringArrayScopeCallsWrapperData");
const IStringArrayStorage_1 = require("../../interfaces/storages/string-array-transformers/IStringArrayStorage");
const Initializable_1 = require("../../decorators/Initializable");
const AbstractStringArrayCallNode_1 = require("./AbstractStringArrayCallNode");
const NodeFactory_1 = require("../../node/NodeFactory");
const NodeUtils_1 = require("../../node/NodeUtils");
let StringArrayScopeCallsWrapperVariableNode = class StringArrayScopeCallsWrapperVariableNode extends AbstractStringArrayCallNode_1.AbstractStringArrayCallNode {
    constructor(identifierNamesGeneratorFactory, stringArrayIndexNodeFactory, customCodeHelperFormatter, stringArrayStorage, arrayUtils, randomGenerator, options) {
        super(identifierNamesGeneratorFactory, stringArrayIndexNodeFactory, customCodeHelperFormatter, stringArrayStorage, arrayUtils, randomGenerator, options);
    }
    initialize(stringArrayScopeCallsWrapperData, stringArrayCallsWrapperData) {
        this.stringArrayScopeCallsWrapperData = stringArrayScopeCallsWrapperData;
        this.stringArrayCallsWrapperData = stringArrayCallsWrapperData;
    }
    getNodeStructure() {
        const structure = NodeFactory_1.NodeFactory.variableDeclarationNode([
            NodeFactory_1.NodeFactory.variableDeclaratorNode(NodeFactory_1.NodeFactory.identifierNode(this.stringArrayScopeCallsWrapperData.name), NodeFactory_1.NodeFactory.identifierNode(this.stringArrayCallsWrapperData.name))
        ], 'const');
        NodeUtils_1.NodeUtils.parentizeAst(structure);
        return [structure];
    }
};
__decorate([
    (0, Initializable_1.initializable)(),
    __metadata("design:type", typeof (_a = typeof IStringArrayScopeCallsWrapperData_1.IStringArrayScopeCallsWrapperData !== "undefined" && IStringArrayScopeCallsWrapperData_1.IStringArrayScopeCallsWrapperData) === "function" ? _a : Object)
], StringArrayScopeCallsWrapperVariableNode.prototype, "stringArrayCallsWrapperData", void 0);
__decorate([
    (0, Initializable_1.initializable)(),
    __metadata("design:type", typeof (_b = typeof IStringArrayScopeCallsWrapperData_1.IStringArrayScopeCallsWrapperData !== "undefined" && IStringArrayScopeCallsWrapperData_1.IStringArrayScopeCallsWrapperData) === "function" ? _b : Object)
], StringArrayScopeCallsWrapperVariableNode.prototype, "stringArrayScopeCallsWrapperData", void 0);
StringArrayScopeCallsWrapperVariableNode = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IIdentifierNamesGenerator)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IStringArrayIndexNode)),
    __param(2, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.ICustomCodeHelperFormatter)),
    __param(3, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IStringArrayStorage)),
    __param(4, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IArrayUtils)),
    __param(5, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(6, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [typeof (_c = typeof TIdentifierNamesGeneratorFactory_1.TIdentifierNamesGeneratorFactory !== "undefined" && TIdentifierNamesGeneratorFactory_1.TIdentifierNamesGeneratorFactory) === "function" ? _c : Object, typeof (_d = typeof TStringArrayIndexNodeFactory_1.TStringArrayIndexNodeFactory !== "undefined" && TStringArrayIndexNodeFactory_1.TStringArrayIndexNodeFactory) === "function" ? _d : Object, typeof (_e = typeof ICustomCodeHelperFormatter_1.ICustomCodeHelperFormatter !== "undefined" && ICustomCodeHelperFormatter_1.ICustomCodeHelperFormatter) === "function" ? _e : Object, typeof (_f = typeof IStringArrayStorage_1.IStringArrayStorage !== "undefined" && IStringArrayStorage_1.IStringArrayStorage) === "function" ? _f : Object, typeof (_g = typeof IArrayUtils_1.IArrayUtils !== "undefined" && IArrayUtils_1.IArrayUtils) === "function" ? _g : Object, typeof (_h = typeof IRandomGenerator_1.IRandomGenerator !== "undefined" && IRandomGenerator_1.IRandomGenerator) === "function" ? _h : Object, typeof (_j = typeof IOptions_1.IOptions !== "undefined" && IOptions_1.IOptions) === "function" ? _j : Object])
], StringArrayScopeCallsWrapperVariableNode);
exports.StringArrayScopeCallsWrapperVariableNode = StringArrayScopeCallsWrapperVariableNode;
