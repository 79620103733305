"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var IgnoredImportObfuscatingGuard_1, _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.IgnoredImportObfuscatingGuard = void 0;
const inversify_1 = require("inversify");
const IOptions_1 = require("../../../interfaces/options/IOptions");
const ObfuscatingGuardResult_1 = require("../../../enums/node/ObfuscatingGuardResult");
const ServiceIdentifiers_1 = require("../../../container/ServiceIdentifiers");
const NodeGuards_1 = require("../../../node/NodeGuards");
let IgnoredImportObfuscatingGuard = IgnoredImportObfuscatingGuard_1 = class IgnoredImportObfuscatingGuard {
    constructor(options) {
        this.options = options;
    }
    static isDynamicImport(node) {
        return NodeGuards_1.NodeGuards.isImportExpressionNode(node);
    }
    static isRequireImport(node) {
        return NodeGuards_1.NodeGuards.isCallExpressionNode(node)
            && NodeGuards_1.NodeGuards.isIdentifierNode(node.callee)
            && node.callee.name === 'require';
    }
    check(node) {
        if (this.options.ignoreImports) {
            const isIgnoredImport = IgnoredImportObfuscatingGuard_1.isDynamicImport(node)
                || IgnoredImportObfuscatingGuard_1.isRequireImport(node);
            if (isIgnoredImport) {
                return ObfuscatingGuardResult_1.ObfuscatingGuardResult.Ignore;
            }
        }
        return ObfuscatingGuardResult_1.ObfuscatingGuardResult.Transform;
    }
};
IgnoredImportObfuscatingGuard = IgnoredImportObfuscatingGuard_1 = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [typeof (_a = typeof IOptions_1.IOptions !== "undefined" && IOptions_1.IOptions) === "function" ? _a : Object])
], IgnoredImportObfuscatingGuard);
exports.IgnoredImportObfuscatingGuard = IgnoredImportObfuscatingGuard;
