"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.AbstractIdentifierNamesGenerator = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const IOptions_1 = require("../../interfaces/options/IOptions");
const IRandomGenerator_1 = require("../../interfaces/utils/IRandomGenerator");
const NodeGuards_1 = require("../../node/NodeGuards");
let AbstractIdentifierNamesGenerator = class AbstractIdentifierNamesGenerator {
    constructor(randomGenerator, options) {
        this.preservedNamesSet = new Set();
        this.lexicalScopesPreservedNamesMap = new Map();
        this.randomGenerator = randomGenerator;
        this.options = options;
    }
    generate(lexicalScopeNode, nameLength) {
        return NodeGuards_1.NodeGuards.isProgramNode(lexicalScopeNode)
            ? this.generateForGlobalScope()
            : this.generateForLexicalScope(lexicalScopeNode);
    }
    preserveName(name) {
        this.preservedNamesSet.add(name);
    }
    preserveNameForLexicalScope(name, lexicalScopeNode) {
        var _a;
        const preservedNamesForLexicalScopeSet = (_a = this.lexicalScopesPreservedNamesMap.get(lexicalScopeNode)) !== null && _a !== void 0 ? _a : new Set();
        preservedNamesForLexicalScopeSet.add(name);
        this.lexicalScopesPreservedNamesMap.set(lexicalScopeNode, preservedNamesForLexicalScopeSet);
    }
    isValidIdentifierName(name) {
        return this.notReservedName(name) && !this.preservedNamesSet.has(name);
    }
    isValidIdentifierNameInLexicalScopes(name, lexicalScopeNodes) {
        var _a;
        if (!this.isValidIdentifierName(name)) {
            return false;
        }
        for (const lexicalScope of lexicalScopeNodes) {
            const preservedNamesForLexicalScopeSet = (_a = this.lexicalScopesPreservedNamesMap.get(lexicalScope)) !== null && _a !== void 0 ? _a : null;
            if (!preservedNamesForLexicalScopeSet) {
                continue;
            }
            if (preservedNamesForLexicalScopeSet.has(name)) {
                return false;
            }
        }
        return true;
    }
    notReservedName(name) {
        return this.options.reservedNames.length
            ? !this.options.reservedNames.some((reservedName) => new RegExp(reservedName, 'g').exec(name) !== null)
            : true;
    }
};
AbstractIdentifierNamesGenerator = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [typeof (_a = typeof IRandomGenerator_1.IRandomGenerator !== "undefined" && IRandomGenerator_1.IRandomGenerator) === "function" ? _a : Object, typeof (_b = typeof IOptions_1.IOptions !== "undefined" && IOptions_1.IOptions) === "function" ? _b : Object])
], AbstractIdentifierNamesGenerator);
exports.AbstractIdentifierNamesGenerator = AbstractIdentifierNamesGenerator;
