"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NodeGuards = void 0;
const NodeType_1 = require("../enums/node/NodeType");
class NodeGuards {
    static isArrayPatternNode(node) {
        return node.type === NodeType_1.NodeType.ArrayPattern;
    }
    static isArrowFunctionExpressionNode(node) {
        return node.type === NodeType_1.NodeType.ArrowFunctionExpression;
    }
    static isAssignmentExpressionNode(node) {
        return node.type === NodeType_1.NodeType.AssignmentExpression;
    }
    static isAssignmentPatternNode(node) {
        return node.type === NodeType_1.NodeType.AssignmentPattern;
    }
    static isAwaitExpressionNode(node) {
        return node.type === NodeType_1.NodeType.AwaitExpression;
    }
    static isBigIntLiteralNode(node) {
        return NodeGuards.isLiteralNode(node) && !!node.bigint;
    }
    static isBlockStatementNode(node) {
        return node.type === NodeType_1.NodeType.BlockStatement;
    }
    static isBreakStatementNode(node) {
        return node.type === NodeType_1.NodeType.BreakStatement;
    }
    static isCallExpressionNode(node) {
        return node.type === NodeType_1.NodeType.CallExpression;
    }
    static isClassBodyNode(node) {
        return node.type === NodeType_1.NodeType.ClassBody;
    }
    static isClassDeclarationNode(node) {
        return node.type === NodeType_1.NodeType.ClassDeclaration && node.id !== null;
    }
    static isConditionalExpressionNode(node) {
        return node.type === NodeType_1.NodeType.ConditionalExpression;
    }
    static isContinueStatementNode(node) {
        return node.type === NodeType_1.NodeType.ContinueStatement;
    }
    static isDirectiveNode(node) {
        return node.type === NodeType_1.NodeType.ExpressionStatement
            && 'directive' in node;
    }
    static isDoWhileStatementNode(node) {
        return node.type === NodeType_1.NodeType.DoWhileStatement;
    }
    static isExportAllDeclarationNode(node) {
        return node.type === NodeType_1.NodeType.ExportAllDeclaration;
    }
    static isExportNamedDeclarationNode(node) {
        return node.type === NodeType_1.NodeType.ExportNamedDeclaration;
    }
    static isExportSpecifierNode(node) {
        return node.type === NodeType_1.NodeType.ExportSpecifier;
    }
    static isExpressionStatementNode(node) {
        return node.type === NodeType_1.NodeType.ExpressionStatement
            && !('directive' in node);
    }
    static isForStatementNode(node) {
        return node.type === NodeType_1.NodeType.ForStatement;
    }
    static isForInStatementNode(node) {
        return node.type === NodeType_1.NodeType.ForInStatement;
    }
    static isForOfStatementNode(node) {
        return node.type === NodeType_1.NodeType.ForOfStatement;
    }
    static isFunctionNode(node) {
        return NodeGuards.isFunctionDeclarationNode(node) ||
            NodeGuards.isFunctionExpressionNode(node) ||
            NodeGuards.isArrowFunctionExpressionNode(node);
    }
    static isFunctionDeclarationNode(node) {
        return node.type === NodeType_1.NodeType.FunctionDeclaration && node.id !== null;
    }
    static isFunctionExpressionNode(node) {
        return node.type === NodeType_1.NodeType.FunctionExpression;
    }
    static isIdentifierNode(node) {
        return node.type === NodeType_1.NodeType.Identifier;
    }
    static isIfStatementNode(node) {
        return node.type === NodeType_1.NodeType.IfStatement;
    }
    static isIfStatementNodeWithSingleStatementBody(node) {
        if (!NodeGuards.isIfStatementNode(node)) {
            return false;
        }
        return !NodeGuards.isBlockStatementNode(node.consequent)
            || (!!node.alternate && !NodeGuards.isBlockStatementNode(node.alternate));
    }
    static isImportDeclarationNode(node) {
        return node.type === NodeType_1.NodeType.ImportDeclaration;
    }
    static isImportExpressionNode(node) {
        return node.type === NodeType_1.NodeType.ImportExpression;
    }
    static isImportSpecifierNode(node) {
        return node.type === NodeType_1.NodeType.ImportSpecifier;
    }
    static isLabelIdentifierNode(node, parentNode) {
        const parentNodeIsLabeledStatementNode = NodeGuards.isLabeledStatementNode(parentNode) && parentNode.label === node;
        const parentNodeIsContinueStatementNode = NodeGuards.isContinueStatementNode(parentNode) && parentNode.label === node;
        const parentNodeIsBreakStatementNode = NodeGuards.isBreakStatementNode(parentNode) && parentNode.label === node;
        return parentNodeIsLabeledStatementNode || parentNodeIsContinueStatementNode || parentNodeIsBreakStatementNode;
    }
    static isLabeledStatementNode(node) {
        return node.type === NodeType_1.NodeType.LabeledStatement;
    }
    static isLiteralNode(node) {
        return node.type === NodeType_1.NodeType.Literal;
    }
    static isLogicalExpressionNode(node) {
        return node.type === NodeType_1.NodeType.LogicalExpression;
    }
    static isMemberExpressionNode(node) {
        return node.type === NodeType_1.NodeType.MemberExpression;
    }
    static isMethodDefinitionNode(node) {
        return node.type === NodeType_1.NodeType.MethodDefinition;
    }
    static isNewExpressionNode(node) {
        return node.type === NodeType_1.NodeType.NewExpression;
    }
    static isNode(object) {
        return object && !object.type !== undefined;
    }
    static isNodeWithLexicalScope(node) {
        return NodeGuards.isProgramNode(node) || NodeGuards.isFunctionNode(node);
    }
    static isNodeWithBlockLexicalScope(node) {
        return NodeGuards.isNodeWithLexicalScope(node) || NodeGuards.isBlockStatementNode(node);
    }
    static isNodeWithSingleStatementBody(node) {
        if (NodeGuards.isIfStatementNode(node)) {
            return NodeGuards.isIfStatementNodeWithSingleStatementBody(node);
        }
        return (NodeGuards.isForStatementNode(node)
            || NodeGuards.isForOfStatementNode(node)
            || NodeGuards.isForInStatementNode(node)
            || NodeGuards.isWhileStatementNode(node)
            || NodeGuards.isDoWhileStatementNode(node)
            || NodeGuards.isWithStatementNode(node)
            || NodeGuards.isLabeledStatementNode(node)) && !NodeGuards.isBlockStatementNode(node.body);
    }
    static isNodeWithLexicalScopeStatements(node, parentNode) {
        return NodeGuards.isProgramNode(node)
            || (NodeGuards.isBlockStatementNode(node) && NodeGuards.nodesWithLexicalStatements.includes(parentNode.type));
    }
    static isNodeWithStatements(node) {
        return NodeGuards.isProgramNode(node)
            || NodeGuards.isBlockStatementNode(node)
            || NodeGuards.isSwitchCaseNode(node);
    }
    static isNodeWithComments(node) {
        return Boolean(node.leadingComments) || Boolean(node.trailingComments);
    }
    static isObjectPatternNode(node) {
        return node.type === NodeType_1.NodeType.ObjectPattern;
    }
    static isObjectExpressionNode(node) {
        return node.type === NodeType_1.NodeType.ObjectExpression;
    }
    static isProgramNode(node) {
        return node.type === NodeType_1.NodeType.Program;
    }
    static isPropertyNode(node) {
        return node.type === NodeType_1.NodeType.Property;
    }
    static isPropertyDefinitionNode(node) {
        return node.type === NodeType_1.NodeType.PropertyDefinition;
    }
    static isRestElementNode(node) {
        return node.type === NodeType_1.NodeType.RestElement;
    }
    static isReturnStatementNode(node) {
        return node.type === NodeType_1.NodeType.ReturnStatement;
    }
    static isSequenceExpressionNode(node) {
        return node.type === NodeType_1.NodeType.SequenceExpression;
    }
    static isSpreadElementNode(node) {
        return node.type === NodeType_1.NodeType.SpreadElement;
    }
    static isSuperNode(node) {
        return node.type === NodeType_1.NodeType.Super;
    }
    static isSwitchCaseNode(node) {
        return node.type === NodeType_1.NodeType.SwitchCase;
    }
    static isTaggedTemplateExpressionNode(node) {
        return node.type === NodeType_1.NodeType.TaggedTemplateExpression;
    }
    static isTemplateLiteralNode(node) {
        return node.type === NodeType_1.NodeType.TemplateLiteral;
    }
    static isThisExpressionNode(node) {
        return node.type === NodeType_1.NodeType.ThisExpression;
    }
    static isUnaryExpressionNode(node) {
        return node.type === NodeType_1.NodeType.UnaryExpression;
    }
    static isVariableDeclarationNode(node) {
        return node.type === NodeType_1.NodeType.VariableDeclaration;
    }
    static isVariableDeclaratorNode(node) {
        return node.type === NodeType_1.NodeType.VariableDeclarator;
    }
    static isWithStatementNode(node) {
        return node.type === NodeType_1.NodeType.WithStatement;
    }
    static isWhileStatementNode(node) {
        return node.type === NodeType_1.NodeType.WhileStatement;
    }
    static isYieldExpressionNode(node) {
        return node.type === NodeType_1.NodeType.YieldExpression;
    }
}
exports.NodeGuards = NodeGuards;
NodeGuards.nodesWithLexicalStatements = [
    NodeType_1.NodeType.ArrowFunctionExpression,
    NodeType_1.NodeType.FunctionDeclaration,
    NodeType_1.NodeType.FunctionExpression,
    NodeType_1.NodeType.MethodDefinition,
];
