"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.customNodesModule = void 0;
const InversifyContainerFacade_1 = require("../../InversifyContainerFacade");
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../ServiceIdentifiers");
const ControlFlowCustomNode_1 = require("../../../enums/custom-nodes/ControlFlowCustomNode");
const DeadCodeInjectionCustomNode_1 = require("../../../enums/custom-nodes/DeadCodeInjectionCustomNode");
const ObjectExpressionKeysTransformerCustomNode_1 = require("../../../enums/custom-nodes/ObjectExpressionKeysTransformerCustomNode");
const StringArrayCustomNode_1 = require("../../../enums/custom-nodes/StringArrayCustomNode");
const BinaryExpressionFunctionNode_1 = require("../../../custom-nodes/control-flow-flattening-nodes/BinaryExpressionFunctionNode");
const BlockStatementControlFlowFlatteningNode_1 = require("../../../custom-nodes/control-flow-flattening-nodes/BlockStatementControlFlowFlatteningNode");
const BlockStatementDeadCodeInjectionNode_1 = require("../../../custom-nodes/dead-code-injection-nodes/BlockStatementDeadCodeInjectionNode");
const CallExpressionControlFlowStorageCallNode_1 = require("../../../custom-nodes/control-flow-flattening-nodes/control-flow-storage-nodes/CallExpressionControlFlowStorageCallNode");
const CallExpressionFunctionNode_1 = require("../../../custom-nodes/control-flow-flattening-nodes/CallExpressionFunctionNode");
const ControlFlowStorageNode_1 = require("../../../custom-nodes/control-flow-flattening-nodes/control-flow-storage-nodes/ControlFlowStorageNode");
const ExpressionWithOperatorControlFlowStorageCallNode_1 = require("../../../custom-nodes/control-flow-flattening-nodes/control-flow-storage-nodes/ExpressionWithOperatorControlFlowStorageCallNode");
const LogicalExpressionFunctionNode_1 = require("../../../custom-nodes/control-flow-flattening-nodes/LogicalExpressionFunctionNode");
const ObjectExpressionVariableDeclarationHostNode_1 = require("../../../custom-nodes/object-expression-keys-transformer-nodes/ObjectExpressionVariableDeclarationHostNode");
const StringArrayCallNode_1 = require("../../../custom-nodes/string-array-nodes/StringArrayCallNode");
const StringArrayHexadecimalNumberIndexNode_1 = require("../../../custom-nodes/string-array-nodes/string-array-index-nodes/StringArrayHexadecimalNumberIndexNode");
const StringArrayHexadecimalNumericStringIndexNode_1 = require("../../../custom-nodes/string-array-nodes/string-array-index-nodes/StringArrayHexadecimalNumericStringIndexNode");
const StringArrayIndexNode_1 = require("../../../enums/custom-nodes/string-array-index-nodes/StringArrayIndexNode");
const StringArrayScopeCallsWrapperFunctionNode_1 = require("../../../custom-nodes/string-array-nodes/StringArrayScopeCallsWrapperFunctionNode");
const StringArrayScopeCallsWrapperVariableNode_1 = require("../../../custom-nodes/string-array-nodes/StringArrayScopeCallsWrapperVariableNode");
const StringLiteralControlFlowStorageCallNode_1 = require("../../../custom-nodes/control-flow-flattening-nodes/control-flow-storage-nodes/StringLiteralControlFlowStorageCallNode");
const StringLiteralNode_1 = require("../../../custom-nodes/control-flow-flattening-nodes/StringLiteralNode");
exports.customNodesModule = new inversify_1.ContainerModule((bind) => {
    bind(ServiceIdentifiers_1.ServiceIdentifiers.Newable__ICustomNode)
        .toConstructor(BinaryExpressionFunctionNode_1.BinaryExpressionFunctionNode)
        .whenTargetNamed(ControlFlowCustomNode_1.ControlFlowCustomNode.BinaryExpressionFunctionNode);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.Newable__ICustomNode)
        .toConstructor(BlockStatementControlFlowFlatteningNode_1.BlockStatementControlFlowFlatteningNode)
        .whenTargetNamed(ControlFlowCustomNode_1.ControlFlowCustomNode.BlockStatementControlFlowFlatteningNode);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.Newable__ICustomNode)
        .toConstructor(CallExpressionControlFlowStorageCallNode_1.CallExpressionControlFlowStorageCallNode)
        .whenTargetNamed(ControlFlowCustomNode_1.ControlFlowCustomNode.CallExpressionControlFlowStorageCallNode);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.Newable__ICustomNode)
        .toConstructor(CallExpressionFunctionNode_1.CallExpressionFunctionNode)
        .whenTargetNamed(ControlFlowCustomNode_1.ControlFlowCustomNode.CallExpressionFunctionNode);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.Newable__ICustomNode)
        .toConstructor(ControlFlowStorageNode_1.ControlFlowStorageNode)
        .whenTargetNamed(ControlFlowCustomNode_1.ControlFlowCustomNode.ControlFlowStorageNode);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.Newable__ICustomNode)
        .toConstructor(ExpressionWithOperatorControlFlowStorageCallNode_1.ExpressionWithOperatorControlFlowStorageCallNode)
        .whenTargetNamed(ControlFlowCustomNode_1.ControlFlowCustomNode.ExpressionWithOperatorControlFlowStorageCallNode);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.Newable__ICustomNode)
        .toConstructor(LogicalExpressionFunctionNode_1.LogicalExpressionFunctionNode)
        .whenTargetNamed(ControlFlowCustomNode_1.ControlFlowCustomNode.LogicalExpressionFunctionNode);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.Newable__ICustomNode)
        .toConstructor(StringLiteralNode_1.StringLiteralNode)
        .whenTargetNamed(ControlFlowCustomNode_1.ControlFlowCustomNode.StringLiteralNode);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.Newable__ICustomNode)
        .toConstructor(StringLiteralControlFlowStorageCallNode_1.StringLiteralControlFlowStorageCallNode)
        .whenTargetNamed(ControlFlowCustomNode_1.ControlFlowCustomNode.StringLiteralControlFlowStorageCallNode);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.Newable__ICustomNode)
        .toConstructor(BlockStatementDeadCodeInjectionNode_1.BlockStatementDeadCodeInjectionNode)
        .whenTargetNamed(DeadCodeInjectionCustomNode_1.DeadCodeInjectionCustomNode.BlockStatementDeadCodeInjectionNode);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.Newable__ICustomNode)
        .toConstructor(ObjectExpressionVariableDeclarationHostNode_1.ObjectExpressionVariableDeclarationHostNode)
        .whenTargetNamed(ObjectExpressionKeysTransformerCustomNode_1.ObjectExpressionKeysTransformerCustomNode.ObjectExpressionVariableDeclarationHostNode);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.Newable__ICustomNode)
        .toConstructor(StringArrayCallNode_1.StringArrayCallNode)
        .whenTargetNamed(StringArrayCustomNode_1.StringArrayCustomNode.StringArrayCallNode);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.Newable__ICustomNode)
        .toConstructor(StringArrayScopeCallsWrapperFunctionNode_1.StringArrayScopeCallsWrapperFunctionNode)
        .whenTargetNamed(StringArrayCustomNode_1.StringArrayCustomNode.StringArrayScopeCallsWrapperFunctionNode);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.Newable__ICustomNode)
        .toConstructor(StringArrayScopeCallsWrapperVariableNode_1.StringArrayScopeCallsWrapperVariableNode)
        .whenTargetNamed(StringArrayCustomNode_1.StringArrayCustomNode.StringArrayScopeCallsWrapperVariableNode);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.IStringArrayIndexNode)
        .to(StringArrayHexadecimalNumberIndexNode_1.StringArrayHexadecimalNumberIndexNode)
        .inSingletonScope()
        .whenTargetNamed(StringArrayIndexNode_1.StringArrayIndexNode.StringArrayHexadecimalNumberIndexNode);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.IStringArrayIndexNode)
        .to(StringArrayHexadecimalNumericStringIndexNode_1.StringArrayHexadecimalNumericStringIndexNode)
        .inSingletonScope()
        .whenTargetNamed(StringArrayIndexNode_1.StringArrayIndexNode.StringArrayHexadecimalNumericStringIndexNode);
    bind(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IControlFlowCustomNode)
        .toFactory(InversifyContainerFacade_1.InversifyContainerFacade
        .getConstructorFactory(ServiceIdentifiers_1.ServiceIdentifiers.Newable__ICustomNode, ServiceIdentifiers_1.ServiceIdentifiers.Factory__IIdentifierNamesGenerator, ServiceIdentifiers_1.ServiceIdentifiers.ICustomCodeHelperFormatter, ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator, ServiceIdentifiers_1.ServiceIdentifiers.IOptions));
    bind(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IDeadCodeInjectionCustomNode)
        .toFactory(InversifyContainerFacade_1.InversifyContainerFacade
        .getConstructorFactory(ServiceIdentifiers_1.ServiceIdentifiers.Newable__ICustomNode, ServiceIdentifiers_1.ServiceIdentifiers.Factory__IIdentifierNamesGenerator, ServiceIdentifiers_1.ServiceIdentifiers.ICustomCodeHelperFormatter, ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator, ServiceIdentifiers_1.ServiceIdentifiers.IOptions));
    bind(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IObjectExpressionKeysTransformerCustomNode)
        .toFactory(InversifyContainerFacade_1.InversifyContainerFacade
        .getConstructorFactory(ServiceIdentifiers_1.ServiceIdentifiers.Newable__ICustomNode, ServiceIdentifiers_1.ServiceIdentifiers.Factory__IIdentifierNamesGenerator, ServiceIdentifiers_1.ServiceIdentifiers.ICustomCodeHelperFormatter, ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator, ServiceIdentifiers_1.ServiceIdentifiers.IOptions));
    bind(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IStringArrayCustomNode)
        .toFactory(InversifyContainerFacade_1.InversifyContainerFacade
        .getConstructorFactory(ServiceIdentifiers_1.ServiceIdentifiers.Newable__ICustomNode, ServiceIdentifiers_1.ServiceIdentifiers.Factory__IIdentifierNamesGenerator, ServiceIdentifiers_1.ServiceIdentifiers.Factory__IStringArrayIndexNode, ServiceIdentifiers_1.ServiceIdentifiers.ICustomCodeHelperFormatter, ServiceIdentifiers_1.ServiceIdentifiers.IStringArrayStorage, ServiceIdentifiers_1.ServiceIdentifiers.IArrayUtils, ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator, ServiceIdentifiers_1.ServiceIdentifiers.IOptions));
    bind(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IStringArrayIndexNode)
        .toFactory(InversifyContainerFacade_1.InversifyContainerFacade
        .getCacheFactory(ServiceIdentifiers_1.ServiceIdentifiers.IStringArrayIndexNode));
});
