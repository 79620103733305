"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NodeTransformer = void 0;
var NodeTransformer;
(function (NodeTransformer) {
    NodeTransformer["BooleanLiteralTransformer"] = "BooleanLiteralTransformer";
    NodeTransformer["BlockStatementControlFlowTransformer"] = "BlockStatementControlFlowTransformer";
    NodeTransformer["BlockStatementSimplifyTransformer"] = "BlockStatementSimplifyTransformer";
    NodeTransformer["ClassFieldTransformer"] = "ClassFieldTransformer";
    NodeTransformer["CommentsTransformer"] = "CommentsTransformer";
    NodeTransformer["CustomCodeHelpersTransformer"] = "CustomCodeHelpersTransformer";
    NodeTransformer["DeadCodeInjectionIdentifiersTransformer"] = "DeadCodeInjectionIdentifiersTransformer";
    NodeTransformer["DeadCodeInjectionTransformer"] = "DeadCodeInjectionTransformer";
    NodeTransformer["DirectivePlacementTransformer"] = "DirectivePlacementTransformer";
    NodeTransformer["EscapeSequenceTransformer"] = "EscapeSequenceTransformer";
    NodeTransformer["EvalCallExpressionTransformer"] = "EvalCallExpressionTransformer";
    NodeTransformer["ExportSpecifierTransformer"] = "ExportSpecifierTransformer";
    NodeTransformer["ExpressionStatementsMergeTransformer"] = "ExpressionStatementsMergeTransformer";
    NodeTransformer["FunctionControlFlowTransformer"] = "FunctionControlFlowTransformer";
    NodeTransformer["IfStatementSimplifyTransformer"] = "IfStatementSimplifyTransformer";
    NodeTransformer["LabeledStatementTransformer"] = "LabeledStatementTransformer";
    NodeTransformer["MemberExpressionTransformer"] = "MemberExpressionTransformer";
    NodeTransformer["MetadataTransformer"] = "MetadataTransformer";
    NodeTransformer["NumberLiteralTransformer"] = "NumberLiteralTransformer";
    NodeTransformer["NumberToNumericalExpressionTransformer"] = "NumberToNumericalExpressionTransformer";
    NodeTransformer["ObfuscatingGuardsTransformer"] = "ObfuscatingGuardsTransformer";
    NodeTransformer["ObjectExpressionKeysTransformer"] = "ObjectExpressionKeysTransformer";
    NodeTransformer["ObjectExpressionTransformer"] = "ObjectExpressionTransformer";
    NodeTransformer["ObjectPatternPropertiesTransformer"] = "ObjectPatternPropertiesTransformer";
    NodeTransformer["ParentificationTransformer"] = "ParentificationTransformer";
    NodeTransformer["RenamePropertiesTransformer"] = "RenamePropertiesTransformer";
    NodeTransformer["ScopeIdentifiersTransformer"] = "ScopeIdentifiersTransformer";
    NodeTransformer["ScopeThroughIdentifiersTransformer"] = "ScopeThroughIdentifiersTransformer";
    NodeTransformer["SplitStringTransformer"] = "SplitStringTransformer";
    NodeTransformer["StringArrayTransformer"] = "StringArrayTransformer";
    NodeTransformer["StringArrayRotateFunctionTransformer"] = "StringArrayRotateFunctionTransformer";
    NodeTransformer["StringArrayScopeCallsWrapperTransformer"] = "StringArrayScopeCallsWrapperTransformer";
    NodeTransformer["TemplateLiteralTransformer"] = "TemplateLiteralTransformer";
    NodeTransformer["VariableDeclarationsMergeTransformer"] = "VariableDeclarationsMergeTransformer";
    NodeTransformer["VariablePreserveTransformer"] = "VariablePreserveTransformer";
})(NodeTransformer = exports.NodeTransformer || (exports.NodeTransformer = {}));
