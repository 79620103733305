"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var StringArrayStorage_1, _a, _b, _c, _d, _e;
Object.defineProperty(exports, "__esModule", { value: true });
exports.StringArrayStorage = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const TIdentifierNamesGeneratorFactory_1 = require("../../types/container/generators/TIdentifierNamesGeneratorFactory");
const IArrayUtils_1 = require("../../interfaces/utils/IArrayUtils");
const ICryptUtilsStringArray_1 = require("../../interfaces/utils/ICryptUtilsStringArray");
const IOptions_1 = require("../../interfaces/options/IOptions");
const IRandomGenerator_1 = require("../../interfaces/utils/IRandomGenerator");
const StringArrayEncoding_1 = require("../../enums/node-transformers/string-array-transformers/StringArrayEncoding");
const MapStorage_1 = require("../MapStorage");
let StringArrayStorage = StringArrayStorage_1 = class StringArrayStorage extends MapStorage_1.MapStorage {
    constructor(identifierNamesGeneratorFactory, arrayUtils, randomGenerator, options, cryptUtilsStringArray) {
        super(randomGenerator, options);
        this.rc4EncodedValuesSourcesCache = new Map();
        this.indexShiftAmount = 0;
        this.rotationAmount = 0;
        this.stringArrayStorageCallsWrapperNamesMap = new Map();
        this.identifierNamesGenerator = identifierNamesGeneratorFactory(options);
        this.arrayUtils = arrayUtils;
        this.cryptUtilsStringArray = cryptUtilsStringArray;
        this.rc4Keys = this.randomGenerator.getRandomGenerator()
            .n(() => this.randomGenerator.getRandomGenerator().string({
            length: StringArrayStorage_1.rc4KeyLength
        }), StringArrayStorage_1.rc4KeysCount);
    }
    initialize() {
        super.initialize();
        this.indexShiftAmount = this.options.stringArrayIndexShift
            ? this.randomGenerator.getRandomInteger(StringArrayStorage_1.minimumIndexShiftAmount, StringArrayStorage_1.maximumIndexShiftAmount)
            : 0;
        this.rotationAmount = this.options.stringArrayRotate
            ? this.randomGenerator.getRandomInteger(StringArrayStorage_1.minimumRotationAmount, StringArrayStorage_1.maximumRotationAmount)
            : 0;
    }
    get(value) {
        return this.getOrSetIfDoesNotExist(value);
    }
    getIndexShiftAmount() {
        return this.indexShiftAmount;
    }
    getRotationAmount() {
        return this.rotationAmount;
    }
    getStorageName() {
        return this.getStorageId();
    }
    getStorageId() {
        if (!this.stringArrayStorageName) {
            this.stringArrayStorageName = this.identifierNamesGenerator
                .generateForGlobalScope(StringArrayStorage_1.stringArrayFunctionNameLength);
        }
        return this.stringArrayStorageName;
    }
    getStorageCallsWrapperName(stringArrayEncoding) {
        var _a;
        const storageCallsWrapperName = (_a = this.stringArrayStorageCallsWrapperNamesMap
            .get(stringArrayEncoding)) !== null && _a !== void 0 ? _a : null;
        if (storageCallsWrapperName) {
            return storageCallsWrapperName;
        }
        const newStorageCallsWrapperName = this.identifierNamesGenerator
            .generateForGlobalScope(StringArrayStorage_1.stringArrayFunctionNameLength);
        this.stringArrayStorageCallsWrapperNamesMap.set(stringArrayEncoding, newStorageCallsWrapperName);
        return newStorageCallsWrapperName;
    }
    rotateStorage() {
        if (!this.getLength()) {
            return;
        }
        this.storage = new Map(this.arrayUtils.rotate(Array.from(this.storage.entries()), this.rotationAmount));
    }
    shuffleStorage() {
        this.storage = new Map(this.arrayUtils
            .shuffle(Array.from(this.storage.entries()))
            .map(([value, stringArrayStorageItemData], index) => {
            stringArrayStorageItemData.index = index;
            return [value, stringArrayStorageItemData];
        })
            .sort(([, stringArrayStorageItemDataA], [, stringArrayStorageItemDataB]) => stringArrayStorageItemDataA.index - stringArrayStorageItemDataB.index));
    }
    getOrSetIfDoesNotExist(value) {
        const { encodedValue, encoding, decodeKey } = this.getEncodedValue(value);
        const cacheKey = `${encodedValue}-${encoding}`;
        const storedStringArrayStorageItemData = this.storage.get(cacheKey);
        if (storedStringArrayStorageItemData) {
            return storedStringArrayStorageItemData;
        }
        const stringArrayStorageItemData = {
            encodedValue,
            encoding,
            decodeKey,
            value,
            index: this.getLength()
        };
        this.storage.set(cacheKey, stringArrayStorageItemData);
        return stringArrayStorageItemData;
    }
    getEncodedValue(value) {
        var _a;
        const encoding = this.options.stringArrayEncoding.length
            ? this.randomGenerator
                .getRandomGenerator()
                .pickone(this.options.stringArrayEncoding)
            : null;
        if (!encoding) {
            throw new Error('`stringArrayEncoding` option array is empty');
        }
        switch (encoding) {
            case StringArrayEncoding_1.StringArrayEncoding.Rc4: {
                const decodeKey = this.randomGenerator.getRandomGenerator().pickone(this.rc4Keys);
                const encodedValue = this.cryptUtilsStringArray.btoa(this.cryptUtilsStringArray.rc4(value, decodeKey));
                const encodedValueSources = (_a = this.rc4EncodedValuesSourcesCache.get(encodedValue)) !== null && _a !== void 0 ? _a : [];
                let encodedValueSourcesLength = encodedValueSources.length;
                const shouldAddValueToSourcesCache = !encodedValueSourcesLength || !encodedValueSources.includes(value);
                if (shouldAddValueToSourcesCache) {
                    encodedValueSources.push(value);
                    encodedValueSourcesLength++;
                }
                this.rc4EncodedValuesSourcesCache.set(encodedValue, encodedValueSources);
                if (encodedValueSourcesLength > 1) {
                    return this.getEncodedValue(value);
                }
                return { encodedValue, encoding, decodeKey };
            }
            case StringArrayEncoding_1.StringArrayEncoding.Base64: {
                const decodeKey = null;
                const encodedValue = this.cryptUtilsStringArray.btoa(value);
                return { encodedValue, encoding, decodeKey };
            }
            default: {
                const decodeKey = null;
                const encodedValue = value;
                return { encodedValue, encoding, decodeKey };
            }
        }
    }
};
StringArrayStorage.minimumRotationAmount = 100;
StringArrayStorage.maximumRotationAmount = 500;
StringArrayStorage.minimumIndexShiftAmount = 100;
StringArrayStorage.maximumIndexShiftAmount = 500;
StringArrayStorage.rc4KeyLength = 4;
StringArrayStorage.rc4KeysCount = 50;
StringArrayStorage.stringArrayFunctionNameLength = 4;
__decorate([
    (0, inversify_1.postConstruct)(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], StringArrayStorage.prototype, "initialize", null);
StringArrayStorage = StringArrayStorage_1 = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IIdentifierNamesGenerator)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IArrayUtils)),
    __param(2, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(3, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __param(4, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.ICryptUtilsStringArray)),
    __metadata("design:paramtypes", [typeof (_a = typeof TIdentifierNamesGeneratorFactory_1.TIdentifierNamesGeneratorFactory !== "undefined" && TIdentifierNamesGeneratorFactory_1.TIdentifierNamesGeneratorFactory) === "function" ? _a : Object, typeof (_b = typeof IArrayUtils_1.IArrayUtils !== "undefined" && IArrayUtils_1.IArrayUtils) === "function" ? _b : Object, typeof (_c = typeof IRandomGenerator_1.IRandomGenerator !== "undefined" && IRandomGenerator_1.IRandomGenerator) === "function" ? _c : Object, typeof (_d = typeof IOptions_1.IOptions !== "undefined" && IOptions_1.IOptions) === "function" ? _d : Object, typeof (_e = typeof ICryptUtilsStringArray_1.ICryptUtilsStringArray !== "undefined" && ICryptUtilsStringArray_1.ICryptUtilsStringArray) === "function" ? _e : Object])
], StringArrayStorage);
exports.StringArrayStorage = StringArrayStorage;
