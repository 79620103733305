"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var JavaScriptObfuscator_1, _a, _b, _c, _d, _e, _f;
Object.defineProperty(exports, "__esModule", { value: true });
exports.JavaScriptObfuscator = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("./container/ServiceIdentifiers");
const escodegen = __importStar(require("@javascript-obfuscator/escodegen"));
const TObfuscationResultFactory_1 = require("./types/container/source-code/TObfuscationResultFactory");
const ICodeTransformersRunner_1 = require("./interfaces/code-transformers/ICodeTransformersRunner");
const ILogger_1 = require("./interfaces/logger/ILogger");
const IOptions_1 = require("./interfaces/options/IOptions");
const IRandomGenerator_1 = require("./interfaces/utils/IRandomGenerator");
const INodeTransformersRunner_1 = require("./interfaces/node-transformers/INodeTransformersRunner");
const CodeTransformer_1 = require("./enums/code-transformers/CodeTransformer");
const CodeTransformationStage_1 = require("./enums/code-transformers/CodeTransformationStage");
const LoggingMessage_1 = require("./enums/logger/LoggingMessage");
const NodeTransformer_1 = require("./enums/node-transformers/NodeTransformer");
const NodeTransformationStage_1 = require("./enums/node-transformers/NodeTransformationStage");
const EcmaVersion_1 = require("./constants/EcmaVersion");
const ASTParserFacade_1 = require("./ASTParserFacade");
const NodeGuards_1 = require("./node/NodeGuards");
const Utils_1 = require("./utils/Utils");
const SourceMapSourcesMode_1 = require("./enums/source-map/SourceMapSourcesMode");
let JavaScriptObfuscator = JavaScriptObfuscator_1 = class JavaScriptObfuscator {
    constructor(codeTransformersRunner, nodeTransformersRunner, randomGenerator, obfuscatedCodeFactory, logger, options) {
        this.codeTransformersRunner = codeTransformersRunner;
        this.nodeTransformersRunner = nodeTransformersRunner;
        this.randomGenerator = randomGenerator;
        this.obfuscationResultFactory = obfuscatedCodeFactory;
        this.logger = logger;
        this.options = options;
    }
    obfuscate(sourceCode) {
        if (typeof sourceCode !== 'string') {
            sourceCode = '';
        }
        const timeStart = Date.now();
        this.logger.info(LoggingMessage_1.LoggingMessage.Version, Utils_1.Utils.buildVersionMessage(process.env.VERSION, process.env.BUILD_TIMESTAMP));
        this.logger.info(LoggingMessage_1.LoggingMessage.ObfuscationStarted);
        this.logger.info(LoggingMessage_1.LoggingMessage.RandomGeneratorSeed, this.randomGenerator.getInputSeed());
        sourceCode = this.runCodeTransformationStage(sourceCode, CodeTransformationStage_1.CodeTransformationStage.PreparingTransformers);
        const astTree = this.parseCode(sourceCode);
        const obfuscatedAstTree = this.transformAstTree(astTree);
        const generatorOutput = this.generateCode(sourceCode, obfuscatedAstTree);
        generatorOutput.code = this.runCodeTransformationStage(generatorOutput.code, CodeTransformationStage_1.CodeTransformationStage.FinalizingTransformers);
        const obfuscationTime = (Date.now() - timeStart) / 1000;
        this.logger.success(LoggingMessage_1.LoggingMessage.ObfuscationCompleted, obfuscationTime);
        return this.getObfuscationResult(generatorOutput);
    }
    parseCode(sourceCode) {
        return ASTParserFacade_1.ASTParserFacade.parse(sourceCode, JavaScriptObfuscator_1.parseOptions);
    }
    transformAstTree(astTree) {
        astTree = this.runNodeTransformationStage(astTree, NodeTransformationStage_1.NodeTransformationStage.Initializing);
        const isEmptyAstTree = NodeGuards_1.NodeGuards.isProgramNode(astTree)
            && !astTree.body.length
            && !astTree.leadingComments
            && !astTree.trailingComments;
        if (isEmptyAstTree) {
            this.logger.warn(LoggingMessage_1.LoggingMessage.EmptySourceCode);
            return astTree;
        }
        astTree = this.runNodeTransformationStage(astTree, NodeTransformationStage_1.NodeTransformationStage.Preparing);
        if (this.options.deadCodeInjection) {
            astTree = this.runNodeTransformationStage(astTree, NodeTransformationStage_1.NodeTransformationStage.DeadCodeInjection);
        }
        if (this.options.controlFlowFlattening) {
            astTree = this.runNodeTransformationStage(astTree, NodeTransformationStage_1.NodeTransformationStage.ControlFlowFlattening);
        }
        if (this.options.renameProperties) {
            astTree = this.runNodeTransformationStage(astTree, NodeTransformationStage_1.NodeTransformationStage.RenameProperties);
        }
        astTree = this.runNodeTransformationStage(astTree, NodeTransformationStage_1.NodeTransformationStage.Converting);
        astTree = this.runNodeTransformationStage(astTree, NodeTransformationStage_1.NodeTransformationStage.RenameIdentifiers);
        astTree = this.runNodeTransformationStage(astTree, NodeTransformationStage_1.NodeTransformationStage.StringArray);
        if (this.options.simplify) {
            astTree = this.runNodeTransformationStage(astTree, NodeTransformationStage_1.NodeTransformationStage.Simplifying);
        }
        astTree = this.runNodeTransformationStage(astTree, NodeTransformationStage_1.NodeTransformationStage.Finalizing);
        return astTree;
    }
    generateCode(sourceCode, astTree) {
        const escodegenParams = {
            ...JavaScriptObfuscator_1.escodegenParams,
            format: {
                compact: this.options.compact
            },
            ...this.options.sourceMap && {
                ...this.options.sourceMapSourcesMode === SourceMapSourcesMode_1.SourceMapSourcesMode.SourcesContent
                    ? {
                        sourceMap: 'sourceMap',
                        sourceContent: sourceCode
                    }
                    : {
                        sourceMap: this.options.inputFileName || 'sourceMap'
                    }
            }
        };
        const generatorOutput = escodegen.generate(astTree, escodegenParams);
        generatorOutput.map = generatorOutput.map ? generatorOutput.map.toString() : '';
        return generatorOutput;
    }
    getObfuscationResult(generatorOutput) {
        return this.obfuscationResultFactory(generatorOutput.code, generatorOutput.map);
    }
    runCodeTransformationStage(code, codeTransformationStage) {
        this.logger.info(LoggingMessage_1.LoggingMessage.CodeTransformationStage, codeTransformationStage);
        return this.codeTransformersRunner.transform(code, JavaScriptObfuscator_1.codeTransformersList, codeTransformationStage);
    }
    runNodeTransformationStage(astTree, nodeTransformationStage) {
        this.logger.info(LoggingMessage_1.LoggingMessage.NodeTransformationStage, nodeTransformationStage);
        return this.nodeTransformersRunner.transform(astTree, JavaScriptObfuscator_1.nodeTransformersList, nodeTransformationStage);
    }
};
JavaScriptObfuscator.parseOptions = {
    ecmaVersion: EcmaVersion_1.ecmaVersion,
    allowHashBang: true,
    allowImportExportEverywhere: true,
    allowReturnOutsideFunction: true,
    locations: true,
    ranges: true
};
JavaScriptObfuscator.escodegenParams = {
    comment: true,
    verbatim: 'x-verbatim-property',
    sourceMapWithCode: true
};
JavaScriptObfuscator.codeTransformersList = [
    CodeTransformer_1.CodeTransformer.HashbangOperatorTransformer
];
JavaScriptObfuscator.nodeTransformersList = [
    NodeTransformer_1.NodeTransformer.BooleanLiteralTransformer,
    NodeTransformer_1.NodeTransformer.BlockStatementControlFlowTransformer,
    NodeTransformer_1.NodeTransformer.BlockStatementSimplifyTransformer,
    NodeTransformer_1.NodeTransformer.ClassFieldTransformer,
    NodeTransformer_1.NodeTransformer.CommentsTransformer,
    NodeTransformer_1.NodeTransformer.CustomCodeHelpersTransformer,
    NodeTransformer_1.NodeTransformer.DeadCodeInjectionTransformer,
    NodeTransformer_1.NodeTransformer.EscapeSequenceTransformer,
    NodeTransformer_1.NodeTransformer.EvalCallExpressionTransformer,
    NodeTransformer_1.NodeTransformer.ExportSpecifierTransformer,
    NodeTransformer_1.NodeTransformer.ExpressionStatementsMergeTransformer,
    NodeTransformer_1.NodeTransformer.FunctionControlFlowTransformer,
    NodeTransformer_1.NodeTransformer.IfStatementSimplifyTransformer,
    NodeTransformer_1.NodeTransformer.LabeledStatementTransformer,
    NodeTransformer_1.NodeTransformer.RenamePropertiesTransformer,
    NodeTransformer_1.NodeTransformer.MemberExpressionTransformer,
    NodeTransformer_1.NodeTransformer.MetadataTransformer,
    NodeTransformer_1.NodeTransformer.NumberLiteralTransformer,
    NodeTransformer_1.NodeTransformer.NumberToNumericalExpressionTransformer,
    NodeTransformer_1.NodeTransformer.ObfuscatingGuardsTransformer,
    NodeTransformer_1.NodeTransformer.ObjectExpressionKeysTransformer,
    NodeTransformer_1.NodeTransformer.ObjectExpressionTransformer,
    NodeTransformer_1.NodeTransformer.ObjectPatternPropertiesTransformer,
    NodeTransformer_1.NodeTransformer.ParentificationTransformer,
    NodeTransformer_1.NodeTransformer.ScopeIdentifiersTransformer,
    NodeTransformer_1.NodeTransformer.ScopeThroughIdentifiersTransformer,
    NodeTransformer_1.NodeTransformer.SplitStringTransformer,
    NodeTransformer_1.NodeTransformer.StringArrayRotateFunctionTransformer,
    NodeTransformer_1.NodeTransformer.StringArrayScopeCallsWrapperTransformer,
    NodeTransformer_1.NodeTransformer.StringArrayTransformer,
    NodeTransformer_1.NodeTransformer.TemplateLiteralTransformer,
    NodeTransformer_1.NodeTransformer.DirectivePlacementTransformer,
    NodeTransformer_1.NodeTransformer.VariableDeclarationsMergeTransformer,
    NodeTransformer_1.NodeTransformer.VariablePreserveTransformer
];
JavaScriptObfuscator = JavaScriptObfuscator_1 = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.ICodeTransformersRunner)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.INodeTransformersRunner)),
    __param(2, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(3, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IObfuscationResult)),
    __param(4, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.ILogger)),
    __param(5, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [typeof (_a = typeof ICodeTransformersRunner_1.ICodeTransformersRunner !== "undefined" && ICodeTransformersRunner_1.ICodeTransformersRunner) === "function" ? _a : Object, typeof (_b = typeof INodeTransformersRunner_1.INodeTransformersRunner !== "undefined" && INodeTransformersRunner_1.INodeTransformersRunner) === "function" ? _b : Object, typeof (_c = typeof IRandomGenerator_1.IRandomGenerator !== "undefined" && IRandomGenerator_1.IRandomGenerator) === "function" ? _c : Object, typeof (_d = typeof TObfuscationResultFactory_1.TObfuscationResultFactory !== "undefined" && TObfuscationResultFactory_1.TObfuscationResultFactory) === "function" ? _d : Object, typeof (_e = typeof ILogger_1.ILogger !== "undefined" && ILogger_1.ILogger) === "function" ? _e : Object, typeof (_f = typeof IOptions_1.IOptions !== "undefined" && IOptions_1.IOptions) === "function" ? _f : Object])
], JavaScriptObfuscator);
exports.JavaScriptObfuscator = JavaScriptObfuscator;
