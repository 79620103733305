"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c;
Object.defineProperty(exports, "__esModule", { value: true });
exports.NumberToNumericalExpressionTransformer = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const INumberNumericalExpressionAnalyzer_1 = require("../../interfaces/analyzers/number-numerical-expression-analyzer/INumberNumericalExpressionAnalyzer");
const IOptions_1 = require("../../interfaces/options/IOptions");
const IRandomGenerator_1 = require("../../interfaces/utils/IRandomGenerator");
const NodeTransformationStage_1 = require("../../enums/node-transformers/NodeTransformationStage");
const AbstractNodeTransformer_1 = require("../AbstractNodeTransformer");
const NodeGuards_1 = require("../../node/NodeGuards");
const NodeFactory_1 = require("../../node/NodeFactory");
const NumberNumericalExpressionAnalyzer_1 = require("../../analyzers/number-numerical-expression-analyzer/NumberNumericalExpressionAnalyzer");
const NumberUtils_1 = require("../../utils/NumberUtils");
const NumericalExpressionDataToNodeConverter_1 = require("../../node/NumericalExpressionDataToNodeConverter");
let NumberToNumericalExpressionTransformer = class NumberToNumericalExpressionTransformer extends AbstractNodeTransformer_1.AbstractNodeTransformer {
    constructor(numberNumericalExpressionAnalyzer, randomGenerator, options) {
        super(randomGenerator, options);
        this.numberNumericalExpressionAnalyzer = numberNumericalExpressionAnalyzer;
    }
    getVisitor(nodeTransformationStage) {
        if (!this.options.numbersToExpressions) {
            return null;
        }
        switch (nodeTransformationStage) {
            case NodeTransformationStage_1.NodeTransformationStage.Converting:
                return {
                    leave: (node, parentNode) => {
                        if (parentNode && NodeGuards_1.NodeGuards.isLiteralNode(node)) {
                            return this.transformNode(node, parentNode);
                        }
                    }
                };
            default:
                return null;
        }
    }
    transformNode(literalNode, parentNode) {
        if (typeof literalNode.value !== 'number') {
            return literalNode;
        }
        if (NodeGuards_1.NodeGuards.isPropertyNode(parentNode) && !parentNode.computed) {
            return literalNode;
        }
        const baseNumber = literalNode.value;
        const [integerPart, decimalPart] = NumberUtils_1.NumberUtils.extractIntegerAndDecimalParts(baseNumber);
        const integerNumberNumericalExpressionData = this.numberNumericalExpressionAnalyzer
            .analyze(integerPart, NumberNumericalExpressionAnalyzer_1.NumberNumericalExpressionAnalyzer.defaultAdditionalPartsCount);
        if (decimalPart) {
            return NumericalExpressionDataToNodeConverter_1.NumericalExpressionDataToNodeConverter.convertFloatNumberData(integerNumberNumericalExpressionData, decimalPart, this.getNumberNumericalExpressionLiteralNode);
        }
        else {
            return NumericalExpressionDataToNodeConverter_1.NumericalExpressionDataToNodeConverter.convertIntegerNumberData(integerNumberNumericalExpressionData, this.getNumberNumericalExpressionLiteralNode);
        }
    }
    getNumberNumericalExpressionLiteralNode(number, isPositiveNumber) {
        const numberLiteralNode = NodeFactory_1.NodeFactory.literalNode(number);
        return isPositiveNumber
            ? numberLiteralNode
            : NodeFactory_1.NodeFactory.unaryExpressionNode('-', numberLiteralNode);
    }
};
NumberToNumericalExpressionTransformer = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.INumberNumericalExpressionAnalyzer)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(2, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [typeof (_a = typeof INumberNumericalExpressionAnalyzer_1.INumberNumericalExpressionAnalyzer !== "undefined" && INumberNumericalExpressionAnalyzer_1.INumberNumericalExpressionAnalyzer) === "function" ? _a : Object, typeof (_b = typeof IRandomGenerator_1.IRandomGenerator !== "undefined" && IRandomGenerator_1.IRandomGenerator) === "function" ? _b : Object, typeof (_c = typeof IOptions_1.IOptions !== "undefined" && IOptions_1.IOptions) === "function" ? _c : Object])
], NumberToNumericalExpressionTransformer);
exports.NumberToNumericalExpressionTransformer = NumberToNumericalExpressionTransformer;
