"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NumberUtils = void 0;
const Utils_1 = require("./Utils");
class NumberUtils {
    static toHex(number) {
        const radix = 16;
        const basePart = typeof number === 'number'
            ? number.toString(radix)
            : `${number.toString(radix)}n`;
        return `${Utils_1.Utils.hexadecimalPrefix}${basePart}`;
    }
    static extractIntegerAndDecimalParts(number) {
        const integerPart = Math.trunc(number);
        const decimalPart = number !== integerPart
            ? number % 1
            : null;
        return [integerPart, decimalPart];
    }
    static isCeil(number) {
        return typeof number === 'number'
            ? number % 1 === 0
            : true;
    }
    static isPositive(number) {
        if (isNaN(number)) {
            throw new Error('Given value is NaN');
        }
        if (number > 0) {
            return true;
        }
        if (number < 0) {
            return false;
        }
        if (1 / number === Number.POSITIVE_INFINITY) {
            return true;
        }
        return false;
    }
    static isUnsafeNumber(number) {
        if (isNaN(number)) {
            throw new Error('Given value is NaN');
        }
        return number < Number.MIN_SAFE_INTEGER || number > Number.MAX_SAFE_INTEGER;
    }
    static getFactors(number) {
        if (number === 0) {
            throw new Error('Invalid number. Allowed only non-zero number');
        }
        number = Math.abs(number);
        if (number === 1) {
            return [-number, number];
        }
        const factors = [];
        const root = Math.sqrt(number);
        const isEven = number % 2 === 0;
        const incrementValue = isEven ? 1 : 2;
        for (let currentFactor = 1; currentFactor <= root; currentFactor += incrementValue) {
            if (number % currentFactor !== 0) {
                continue;
            }
            factors.push(...[-currentFactor, currentFactor]);
            const compliment = number / currentFactor;
            if (compliment !== currentFactor) {
                factors.push(...[-compliment, compliment]);
            }
        }
        return factors.sort((a, b) => a - b);
    }
}
exports.NumberUtils = NumberUtils;
