"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.HashbangOperatorTransformer = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const IOptions_1 = require("../../interfaces/options/IOptions");
const IRandomGenerator_1 = require("../../interfaces/utils/IRandomGenerator");
const CodeTransformationStage_1 = require("../../enums/code-transformers/CodeTransformationStage");
const AbstractCodeTransformer_1 = require("../AbstractCodeTransformer");
let HashbangOperatorTransformer = class HashbangOperatorTransformer extends AbstractCodeTransformer_1.AbstractCodeTransformer {
    constructor(randomGenerator, options) {
        super(randomGenerator, options);
        this.hashbangOperatorLine = null;
    }
    transformCode(code, codeTransformationStage) {
        switch (codeTransformationStage) {
            case CodeTransformationStage_1.CodeTransformationStage.PreparingTransformers:
                return this.removeAndSaveHashbangOperatorLine(code);
            case CodeTransformationStage_1.CodeTransformationStage.FinalizingTransformers:
                return this.appendSavedHashbangOperatorLine(code);
            default:
                return code;
        }
    }
    removeAndSaveHashbangOperatorLine(code) {
        return code
            .replace(/^#!.*$(\r?\n)*/m, (substring) => {
            if (substring) {
                this.hashbangOperatorLine = substring;
            }
            return '';
        })
            .trim();
    }
    appendSavedHashbangOperatorLine(code) {
        var _a;
        return `${(_a = this.hashbangOperatorLine) !== null && _a !== void 0 ? _a : ''}${code}`;
    }
};
HashbangOperatorTransformer = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [typeof (_a = typeof IRandomGenerator_1.IRandomGenerator !== "undefined" && IRandomGenerator_1.IRandomGenerator) === "function" ? _a : Object, typeof (_b = typeof IOptions_1.IOptions !== "undefined" && IOptions_1.IOptions) === "function" ? _b : Object])
], HashbangOperatorTransformer);
exports.HashbangOperatorTransformer = HashbangOperatorTransformer;
