"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var StringLiteralControlFlowReplacer_1, _a, _b, _c;
Object.defineProperty(exports, "__esModule", { value: true });
exports.StringLiteralControlFlowReplacer = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../../container/ServiceIdentifiers");
const TControlFlowCustomNodeFactory_1 = require("../../../types/container/custom-nodes/TControlFlowCustomNodeFactory");
const IOptions_1 = require("../../../interfaces/options/IOptions");
const IRandomGenerator_1 = require("../../../interfaces/utils/IRandomGenerator");
const ControlFlowCustomNode_1 = require("../../../enums/custom-nodes/ControlFlowCustomNode");
const AbstractControlFlowReplacer_1 = require("./AbstractControlFlowReplacer");
const NodeGuards_1 = require("../../../node/NodeGuards");
const NodeLiteralUtils_1 = require("../../../node/NodeLiteralUtils");
let StringLiteralControlFlowReplacer = StringLiteralControlFlowReplacer_1 = class StringLiteralControlFlowReplacer extends AbstractControlFlowReplacer_1.AbstractControlFlowReplacer {
    constructor(controlFlowCustomNodeFactory, randomGenerator, options) {
        super(controlFlowCustomNodeFactory, randomGenerator, options);
    }
    replace(literalNode, parentNode, controlFlowStorage) {
        if (NodeGuards_1.NodeGuards.isPropertyNode(parentNode) && parentNode.key === literalNode) {
            return literalNode;
        }
        if (!NodeLiteralUtils_1.NodeLiteralUtils.isStringLiteralNode(literalNode) || literalNode.value.length < 3) {
            return literalNode;
        }
        const replacerId = String(literalNode.value);
        const literalFunctionCustomNode = this.controlFlowCustomNodeFactory(ControlFlowCustomNode_1.ControlFlowCustomNode.StringLiteralNode);
        literalFunctionCustomNode.initialize(literalNode.value);
        const storageKey = this.insertCustomNodeToControlFlowStorage(literalFunctionCustomNode, controlFlowStorage, replacerId, StringLiteralControlFlowReplacer_1.usingExistingIdentifierChance);
        return this.getControlFlowStorageCallNode(controlFlowStorage.getStorageId(), storageKey);
    }
    getControlFlowStorageCallNode(controlFlowStorageId, storageKey) {
        const controlFlowStorageCallCustomNode = this.controlFlowCustomNodeFactory(ControlFlowCustomNode_1.ControlFlowCustomNode.StringLiteralControlFlowStorageCallNode);
        controlFlowStorageCallCustomNode.initialize(controlFlowStorageId, storageKey);
        const statementNode = controlFlowStorageCallCustomNode.getNode()[0];
        if (!statementNode || !NodeGuards_1.NodeGuards.isExpressionStatementNode(statementNode)) {
            throw new Error('`controlFlowStorageCallCustomNode.getNode()[0]` should returns array with `ExpressionStatement` node');
        }
        return statementNode.expression;
    }
};
StringLiteralControlFlowReplacer.usingExistingIdentifierChance = 1;
StringLiteralControlFlowReplacer = StringLiteralControlFlowReplacer_1 = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IControlFlowCustomNode)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(2, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [typeof (_a = typeof TControlFlowCustomNodeFactory_1.TControlFlowCustomNodeFactory !== "undefined" && TControlFlowCustomNodeFactory_1.TControlFlowCustomNodeFactory) === "function" ? _a : Object, typeof (_b = typeof IRandomGenerator_1.IRandomGenerator !== "undefined" && IRandomGenerator_1.IRandomGenerator) === "function" ? _b : Object, typeof (_c = typeof IOptions_1.IOptions !== "undefined" && IOptions_1.IOptions) === "function" ? _c : Object])
], StringLiteralControlFlowReplacer);
exports.StringLiteralControlFlowReplacer = StringLiteralControlFlowReplacer;
