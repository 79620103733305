"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParentificationTransformer = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../container/ServiceIdentifiers");
const IOptions_1 = require("../../interfaces/options/IOptions");
const IRandomGenerator_1 = require("../../interfaces/utils/IRandomGenerator");
const NodeTransformationStage_1 = require("../../enums/node-transformers/NodeTransformationStage");
const AbstractNodeTransformer_1 = require("../AbstractNodeTransformer");
const NodeUtils_1 = require("../../node/NodeUtils");
let ParentificationTransformer = class ParentificationTransformer extends AbstractNodeTransformer_1.AbstractNodeTransformer {
    constructor(randomGenerator, options) {
        super(randomGenerator, options);
    }
    getVisitor(nodeTransformationStage) {
        switch (nodeTransformationStage) {
            case NodeTransformationStage_1.NodeTransformationStage.Preparing:
                return {
                    enter: (node, parentNode) => {
                        return this.transformNode(node, parentNode);
                    }
                };
            default:
                return null;
        }
    }
    transformNode(node, parentNode) {
        return NodeUtils_1.NodeUtils.parentizeNode(node, parentNode);
    }
};
ParentificationTransformer = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [typeof (_a = typeof IRandomGenerator_1.IRandomGenerator !== "undefined" && IRandomGenerator_1.IRandomGenerator) === "function" ? _a : Object, typeof (_b = typeof IOptions_1.IOptions !== "undefined" && IOptions_1.IOptions) === "function" ? _b : Object])
], ParentificationTransformer);
exports.ParentificationTransformer = ParentificationTransformer;
