"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForceTransformStringObfuscatingGuard = void 0;
const inversify_1 = require("inversify");
const IOptions_1 = require("../../../interfaces/options/IOptions");
const ObfuscatingGuardResult_1 = require("../../../enums/node/ObfuscatingGuardResult");
const ServiceIdentifiers_1 = require("../../../container/ServiceIdentifiers");
const NodeGuards_1 = require("../../../node/NodeGuards");
let ForceTransformStringObfuscatingGuard = class ForceTransformStringObfuscatingGuard {
    constructor(options) {
        this.options = options;
    }
    check(node) {
        if (this.options.forceTransformStrings.length
            && NodeGuards_1.NodeGuards.isLiteralNode(node)
            && typeof node.value === 'string') {
            return !this.isForceTransformString(node.value)
                ? ObfuscatingGuardResult_1.ObfuscatingGuardResult.Transform
                : ObfuscatingGuardResult_1.ObfuscatingGuardResult.ForceTransform;
        }
        return ObfuscatingGuardResult_1.ObfuscatingGuardResult.Transform;
    }
    isForceTransformString(value) {
        return this.options.forceTransformStrings
            .some((forceTransformString) => {
            return new RegExp(forceTransformString, 'g').exec(value) !== null;
        });
    }
};
ForceTransformStringObfuscatingGuard = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [typeof (_a = typeof IOptions_1.IOptions !== "undefined" && IOptions_1.IOptions) === "function" ? _a : Object])
], ForceTransformStringObfuscatingGuard);
exports.ForceTransformStringObfuscatingGuard = ForceTransformStringObfuscatingGuard;
