"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c, _d, _e;
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConsoleOutputCodeHelperGroup = void 0;
const inversify_1 = require("inversify");
const ServiceIdentifiers_1 = require("../../../container/ServiceIdentifiers");
const TCustomCodeHelperFactory_1 = require("../../../types/container/custom-code-helpers/TCustomCodeHelperFactory");
const TIdentifierNamesGeneratorFactory_1 = require("../../../types/container/generators/TIdentifierNamesGeneratorFactory");
const IOptions_1 = require("../../../interfaces/options/IOptions");
const IRandomGenerator_1 = require("../../../interfaces/utils/IRandomGenerator");
const Initializable_1 = require("../../../decorators/Initializable");
const CustomCodeHelper_1 = require("../../../enums/custom-code-helpers/CustomCodeHelper");
const NodeTransformationStage_1 = require("../../../enums/node-transformers/NodeTransformationStage");
const AbstractCustomCodeHelperGroup_1 = require("../../AbstractCustomCodeHelperGroup");
const NodeAppender_1 = require("../../../node/NodeAppender");
const NodeLexicalScopeUtils_1 = require("../../../node/NodeLexicalScopeUtils");
let ConsoleOutputCodeHelperGroup = class ConsoleOutputCodeHelperGroup extends AbstractCustomCodeHelperGroup_1.AbstractCustomCodeHelperGroup {
    constructor(customCodeHelperFactory, identifierNamesGeneratorFactory, randomGenerator, options) {
        super(identifierNamesGeneratorFactory, randomGenerator, options);
        this.customCodeHelperFactory = customCodeHelperFactory;
    }
    appendOnPreparingStage(nodeWithStatements, callsGraphData) {
        var _a;
        if (!this.options.disableConsoleOutput) {
            return;
        }
        const randomCallsGraphIndex = this.getRandomCallsGraphIndex(callsGraphData.length);
        const consoleOutputDisableHostNode = callsGraphData.length
            ? NodeAppender_1.NodeAppender.getOptimalBlockScope(callsGraphData, randomCallsGraphIndex)
            : nodeWithStatements;
        const callsControllerHostNode = callsGraphData.length
            ? NodeAppender_1.NodeAppender.getOptimalBlockScope(callsGraphData, randomCallsGraphIndex, 1)
            : nodeWithStatements;
        const consoleOutputDisableLexicalScopeNode = (_a = NodeLexicalScopeUtils_1.NodeLexicalScopeUtils
            .getLexicalScope(consoleOutputDisableHostNode)) !== null && _a !== void 0 ? _a : null;
        const consoleOutputDisableFunctionName = consoleOutputDisableLexicalScopeNode
            ? this.identifierNamesGenerator.generate(consoleOutputDisableLexicalScopeNode)
            : this.identifierNamesGenerator.generateNext();
        const callsControllerFunctionName = consoleOutputDisableLexicalScopeNode
            ? this.identifierNamesGenerator.generate(consoleOutputDisableLexicalScopeNode)
            : this.identifierNamesGenerator.generateNext();
        this.appendCustomNodeIfExist(CustomCodeHelper_1.CustomCodeHelper.ConsoleOutputDisable, (customCodeHelper) => {
            customCodeHelper.initialize(callsControllerFunctionName, consoleOutputDisableFunctionName);
            NodeAppender_1.NodeAppender.prepend(consoleOutputDisableHostNode, customCodeHelper.getNode());
        });
        this.appendCustomNodeIfExist(CustomCodeHelper_1.CustomCodeHelper.CallsControllerFunction, (customCodeHelper) => {
            customCodeHelper.initialize(NodeTransformationStage_1.NodeTransformationStage.Preparing, callsControllerFunctionName);
            NodeAppender_1.NodeAppender.prepend(callsControllerHostNode, customCodeHelper.getNode());
        });
    }
    initialize() {
        this.customCodeHelpers = new Map();
        if (!this.options.disableConsoleOutput) {
            return;
        }
        const consoleOutputDisableExpressionCodeHelper = this.customCodeHelperFactory(CustomCodeHelper_1.CustomCodeHelper.ConsoleOutputDisable);
        const callsControllerFunctionCodeHelper = this.customCodeHelperFactory(CustomCodeHelper_1.CustomCodeHelper.CallsControllerFunction);
        this.customCodeHelpers.set(CustomCodeHelper_1.CustomCodeHelper.ConsoleOutputDisable, consoleOutputDisableExpressionCodeHelper);
        this.customCodeHelpers.set(CustomCodeHelper_1.CustomCodeHelper.CallsControllerFunction, callsControllerFunctionCodeHelper);
    }
};
__decorate([
    (0, Initializable_1.initializable)(),
    __metadata("design:type", typeof (_a = typeof Map !== "undefined" && Map) === "function" ? _a : Object)
], ConsoleOutputCodeHelperGroup.prototype, "customCodeHelpers", void 0);
ConsoleOutputCodeHelperGroup = __decorate([
    (0, inversify_1.injectable)(),
    __param(0, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__ICustomCodeHelper)),
    __param(1, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.Factory__IIdentifierNamesGenerator)),
    __param(2, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IRandomGenerator)),
    __param(3, (0, inversify_1.inject)(ServiceIdentifiers_1.ServiceIdentifiers.IOptions)),
    __metadata("design:paramtypes", [typeof (_b = typeof TCustomCodeHelperFactory_1.TCustomCodeHelperFactory !== "undefined" && TCustomCodeHelperFactory_1.TCustomCodeHelperFactory) === "function" ? _b : Object, typeof (_c = typeof TIdentifierNamesGeneratorFactory_1.TIdentifierNamesGeneratorFactory !== "undefined" && TIdentifierNamesGeneratorFactory_1.TIdentifierNamesGeneratorFactory) === "function" ? _c : Object, typeof (_d = typeof IRandomGenerator_1.IRandomGenerator !== "undefined" && IRandomGenerator_1.IRandomGenerator) === "function" ? _d : Object, typeof (_e = typeof IOptions_1.IOptions !== "undefined" && IOptions_1.IOptions) === "function" ? _e : Object])
], ConsoleOutputCodeHelperGroup);
exports.ConsoleOutputCodeHelperGroup = ConsoleOutputCodeHelperGroup;
